import { LeftMenuDesktop } from "../../boards/components/LeftMenu";
import { Button } from "~/components/Button";
import { Settings } from "lucide-react";
import { WalletActivityBadge } from "~/components/Badges/WalletActivityBadge";
import { hashShortener } from "~/utils/addressShortener";
import { SerializeFrom } from "@remix-run/node";
import useTimeAgo from "~/hooks/useTimeAgo";
import { WalletActivityFilter } from "~/models/__constants__";
import { BlurHashImage } from "~/components/BlurhashImage";

export type DummyFeedItem = {
  timestamp: string;
  collection: { imageUrl: string; name: string };
  tokenId: string;
  filter: WalletActivityFilter;
  quantity: number;
  wallet: string;
};

function chunkArrayInGroups(arr: any[], numberOfChunks: number) {
  var result = [];
  var chunkSize = Math.ceil(arr.length / numberOfChunks);

  for (var i = 0; i < numberOfChunks; i++) {
    let chunkStart = i * chunkSize;
    let chunkEnd = chunkStart + chunkSize;
    result.push(arr.slice(chunkStart, chunkEnd));
  }

  return result;
}

export const DummyBoard = ({ items }: { items: DummyFeedItem[] }) => {
  const user = {
    email: "sample@sample.com",
    boards: [
      {
        _id: "123",
        name: "sample board",
        emojiUrl: null,
        order: 1,
        newAlertsCount: 0,
      },
    ],
  };
  const itemList = chunkArrayInGroups(items, 5);
  return (
    <div className="flex bg-primary-background w-full h-screen gap-1">
      <div className="hidden sm:block min-w-[234px] h-full bg-primary-foreground">
        <LeftMenuDesktop user={user} />
      </div>

      <div
        id="scrollable-container"
        className="flex-grow h-full w-full overflow-x-auto flex flex-col"
      >
        <div className="h-[calc(100vh-116px)] md:h-full">
          <div className="flex items-center h-full">
            <div className="flex gap-2 sm:gap-1 h-full">
              {itemList.map((_items, i) => (
                <DummyColumn key={i} items={_items} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DummyColumn = ({ items }: { items: DummyFeedItem[] }) => {
  return (
    <div className="sm:w-[270px] h-full bg-primary-foreground overflow-hidden mx-auto transition-all duration-300">
      <div className="h-full">
        <div className="sticky top-0 bg-primary-foreground z-10">
          <div className="px-3 py-3 flex items-center justify-between">
            <div className="text-base text-secondary font-bold truncate text-ellipsis">
              sample column
            </div>
            <div className="flex items-center">
              <Button variant={"transparent"} size={"icon"} className="w-8 h-8">
                <Settings className="w-4 h-4 text-gray-400" />
              </Button>
            </div>
          </div>
          <hr className="border-secondary/10" />
        </div>
        <div className="sticky h-full">
          <div className="h-[calc(100%-94px)] overflow-y-auto overflow-x-hidden">
            {items.map((item, i) => (
              <DummyFeedItem key={i} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const DummyFeedItem = ({ item }: { item: SerializeFrom<DummyFeedItem> }) => {
  const timeAgo = useTimeAgo({ groupDate: item.timestamp });

  return (
    <div>
      <div className="flex items-center px-[7px] py-4 h-[109px]">
        <div className="flex items-start gap-2 w-full cursor-pointer">
          <BlurHashImage width={48} height={48} src={item.collection.imageUrl} />
          <div className="w-full">
            <div className="flex items-start justify-between gap-1">
              <div className="font-semibold text-xs text-secondary truncate w-[170px]">
                {item.collection.name} {item.tokenId && `#${item.tokenId}`}
              </div>
              <div className="text-xs text-secondary/50 whitespace-nowrap">{timeAgo}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap mt-1">
              <WalletActivityBadge type={item.filter} quantity={item.quantity} />
            </div>
            <div className="flex items-center gap-1 mt-2">
              <div className="w-[18px] h-[18px] rounded-full bg-slate-600 bg-[url('/assets/images/avatar.png')] bg-cover"></div>
              <div className="text-xs text-secondary/50">{hashShortener(item.wallet)}</div>
            </div>
          </div>
        </div>
      </div>

      <hr className="border-secondary/10" />
    </div>
  );
};
