export const feedDummyData = [
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2743aa312d3cb3f75f86a",
    },
    transfer: {
      _id: {
        $oid: "65f2743a984769f352ee14d8",
      },
      batch_transfer_index: 0,
      block_hash: "0xea825202e1f298e50a6f6208ad372cba5669fe78ff171b500bb890cea2d8d3df",
      chain: "ethereum",
      log_index: 121,
      transaction: "0x8dfb86369bdabb323ab333c21bd4eafd7e3fbe2ef0f1ad4a9017bb1abb4e6cdc",
      block_number: 19430694,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.3247",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:51:11.000Z",
      },
      to_address: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      token_id: "3247",
      transaction_initiator: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      transaction_to_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      upsertedAt: {
        $date: "2024-03-14T03:51:22.621Z",
      },
      walletAddresses: [
        "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
        "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2746da312d3cb3f75f875",
    },
    transfer: {
      _id: {
        $oid: "65f2746d984769f352ee2a10",
      },
      batch_transfer_index: 0,
      block_hash: "0xea825202e1f298e50a6f6208ad372cba5669fe78ff171b500bb890cea2d8d3df",
      chain: "ethereum",
      log_index: 166,
      transaction: "0x8ab27c5bc685accf57d942636304e5b49e7ea0c37216bfbc042ca123c9879369",
      block_number: 19430694,
      collection_id: "25be4e15d71b1dd97830dba4b9224e1c",
      contract_address: "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
      event_type: "transfer",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0xba30e5f9bb24caa003e9f2f0497ad287fdf95623.4375",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:51:11.000Z",
      },
      to_address: "0xDf8B9d67BCa5392bdA67cC9A1F79f99f1B858a1F",
      token_id: "4375",
      transaction_initiator: "0xDf8B9d67BCa5392bdA67cC9A1F79f99f1B858a1F",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T03:52:13.056Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xDf8B9d67BCa5392bdA67cC9A1F79f99f1B858a1F",
      ],
    },
    collection: {
      name: "Bored Ape Kennel Club",
      imageUrl:
        "https://lh3.googleusercontent.com/9C9KKwWh9np4FtSlKoE4WWS6GjXpE8eSZ5LH_UV_qAE09Mrsn9O-fCSJcgE7r9XtBdf7ArCM6tgO0V4eHG9fVWQ5Z2fqCDMZDcI",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2744da2477ab2e40b4f51",
    },
    transfer: {
      _id: {
        $oid: "65f2744d984769f352ee1dc2",
      },
      batch_transfer_index: 0,
      block_hash: "0xbefc924b80109465983e17c3dcc05c05ad9f58a1d70a72a82445b68a04480367",
      chain: "ethereum",
      log_index: 317,
      transaction: "0xb3cd33bcb27735faf609f887879eb580effd11ddbad7add8caafea851b67b603",
      block_number: 19430695,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.4335",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:51:23.000Z",
      },
      to_address: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      token_id: "4335",
      transaction_initiator: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      transaction_to_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      upsertedAt: {
        $date: "2024-03-14T03:51:41.436Z",
      },
      walletAddresses: [
        "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
        "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2745bb616dcc701c5cd9e",
    },
    transfer: {
      _id: {
        $oid: "65f2745b984769f352ee24c4",
      },
      batch_transfer_index: 0,
      block_hash: "0x3cc9f2d25bf790d98cd98de745b506d95d3e84a9af484529d088771b800c9309",
      chain: "ethereum",
      log_index: 212,
      transaction: "0x82dbdb68c3acd1a6ccf8cc274396d3b7cac46ea0f7a1b6f81b62f2bb050ad2eb",
      block_number: 19430696,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.3522",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:51:35.000Z",
      },
      to_address: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      token_id: "3522",
      transaction_initiator: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      transaction_to_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      upsertedAt: {
        $date: "2024-03-14T03:51:55.345Z",
      },
      walletAddresses: [
        "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
        "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f27477a312d3cb3f75f882",
    },
    transfer: {
      _id: {
        $oid: "65f27477984769f352ee2e5a",
      },
      batch_transfer_index: 0,
      block_hash: "0x061387e3c8be968b2f9e308d7851881f88f2943b7843b7fcb08701511f91ad73",
      chain: "ethereum",
      log_index: 137,
      transaction: "0x5e8684b6555b5e0a060569a0dd58f2911ff976b385b8ed39a2698b76ca0e7826",
      block_number: 19430697,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.589",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:51:47.000Z",
      },
      to_address: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      token_id: "589",
      transaction_initiator: "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      transaction_to_address: "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
      upsertedAt: {
        $date: "2024-03-14T03:52:23.870Z",
      },
      walletAddresses: [
        "0xD0C6e59B50C32530C627107F50Acc71958C4341F",
        "0x7a0e5A93B78D5825eD2a1BAC2D991689E62b374D",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f2748da312d3cb3f75f888",
    },
    transfer: {
      _id: {
        $oid: "65f2748d984769f352ee3692",
      },
      batch_transfer_index: 0,
      block_hash: "0x80ecf2ee98717adcd1f82c6d94ff733a0cb6cb46727d19d2c20deca73efe2cbd",
      chain: "ethereum",
      log_index: 238,
      transaction: "0xbacbad524fe7505940622ecfab11a396816f16d3f75083ef7a827aba5bd42707",
      block_number: 19430700,
      collection_id: "4f66180165b8624e9f3dc687e09a2e6f",
      contract_address: "0x790B2cF29Ed4F310bf7641f013C65D4560d28371",
      event_type: "sale",
      from_address: "0x184A119100Bd094d0BDFFD1C17542aDA4cAa1C06",
      nft_id: "ethereum.0x790b2cf29ed4f310bf7641f013c65d4560d28371.55680",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "350000000000000000",
        },
        total_price: {
          $numberLong: "350000000000000000",
        },
        unit_price_usd_cents: 139538,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T03:52:23.000Z",
      },
      to_address: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      token_id: "55680",
      transaction_initiator: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      transaction_to_address: "0xb233e3602BB06AA2c2dB0982BBaf33c2b15184C9",
      upsertedAt: {
        $date: "2024-03-14T03:52:45.719Z",
      },
      walletAddresses: [
        "0x184A119100Bd094d0BDFFD1C17542aDA4cAa1C06",
        "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      ],
    },
    collection: {
      name: "Otherdeed Expanded",
      imageUrl:
        "https://lh3.googleusercontent.com/UUjbjykkSWIvbyuKKj2EVZdZctomu1oLlwItL4hCpcmNV4q7Ce0ftqVTXHc80V3-3qe30IPbO5NPsE_31TQgXaSi-OwCeKMt7Fw",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f2748fa2477ab2e40b4f57",
    },
    transfer: {
      _id: {
        $oid: "65f2748f984769f352ee3750",
      },
      batch_transfer_index: 0,
      block_hash: "0x88eaa74af35b6c6befbd6dc0855237cfaca6bcfb460933c4b4304d71cfb3504c",
      chain: "ethereum",
      log_index: 209,
      transaction: "0xb26fb5351eeb8e49ab18d8bdc7e7cf5beb1a4aa20a69348d9abe3e2026cbff62",
      block_number: 19430701,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.12590",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "2368000000000000000",
        },
        total_price: {
          $numberLong: "2368000000000000000",
        },
        unit_price_usd_cents: 944072,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T03:52:35.000Z",
      },
      to_address: "0x3f0a5DCd2EA710070cE7D026704583D9c779Ed62",
      token_id: "12590",
      transaction_initiator: "0x3f0a5DCd2EA710070cE7D026704583D9c779Ed62",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T03:52:47.671Z",
      },
      walletAddresses: [
        "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
        "0x3f0a5DCd2EA710070cE7D026704583D9c779Ed62",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f2748fa2477ab2e40b4f59",
    },
    transfer: {
      _id: {
        $oid: "65f2748f984769f352ee3750",
      },
      batch_transfer_index: 0,
      block_hash: "0x88eaa74af35b6c6befbd6dc0855237cfaca6bcfb460933c4b4304d71cfb3504c",
      chain: "ethereum",
      log_index: 209,
      transaction: "0xb26fb5351eeb8e49ab18d8bdc7e7cf5beb1a4aa20a69348d9abe3e2026cbff62",
      block_number: 19430701,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.12590",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "2368000000000000000",
        },
        total_price: {
          $numberLong: "2368000000000000000",
        },
        unit_price_usd_cents: 944072,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T03:52:35.000Z",
      },
      to_address: "0x3f0a5DCd2EA710070cE7D026704583D9c779Ed62",
      token_id: "12590",
      transaction_initiator: "0x3f0a5DCd2EA710070cE7D026704583D9c779Ed62",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T03:52:47.671Z",
      },
      walletAddresses: [
        "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
        "0x3f0a5DCd2EA710070cE7D026704583D9c779Ed62",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f274abb616dcc701c5cdb1",
    },
    transfer: {
      _id: {
        $oid: "65f274ab984769f352ee4489",
      },
      batch_transfer_index: 0,
      block_hash: "0x2aebbf19ae9a1b821d64647029583580e8e26fe6b0abb1ee0685f5cada5898a2",
      chain: "ethereum",
      log_index: 261,
      transaction: "0x33890bc16f1537d4c50a87de2c33c1a97b0b5850039d5e8ede0935aeca7f8919",
      block_number: 19430703,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.9071",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:52:59.000Z",
      },
      to_address: "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      token_id: "9071",
      transaction_initiator: "0x7cB6Ef54Ea9AE19DD769a1E5b5f8A6182b6cC5D1",
      transaction_to_address: "0x56Fb6A63314bD353Fc9568584DBD96Bf59b66e3B",
      upsertedAt: {
        $date: "2024-03-14T03:53:15.282Z",
      },
      walletAddresses: [
        "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
        "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f274eeb616dcc701c5cdbf",
    },
    transfer: {
      _id: {
        $oid: "65f274ee984769f352ee7949",
      },
      batch_transfer_index: 0,
      block_hash: "0x2aebbf19ae9a1b821d64647029583580e8e26fe6b0abb1ee0685f5cada5898a2",
      chain: "ethereum",
      log_index: 273,
      transaction: "0x41c2209a5a3ff33d8d083ff93bce4f9170f19f3b92899a645273f56169be3057",
      block_number: 19430703,
      collection_id: "25be4e15d71b1dd97830dba4b9224e1c",
      contract_address: "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
      event_type: "transfer",
      from_address: "0x49AD262C49C7aA708Cc2DF262eD53B64A17Dd5EE",
      nft_id: "ethereum.0xba30e5f9bb24caa003e9f2f0497ad287fdf95623.3003",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:52:59.000Z",
      },
      to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      token_id: "3003",
      transaction_initiator: "0x1eCbC285F34390516FB69797949D9d4Ca3F86CFf",
      transaction_to_address: "0x49AD262C49C7aA708Cc2DF262eD53B64A17Dd5EE",
      upsertedAt: {
        $date: "2024-03-14T03:54:22.113Z",
      },
      walletAddresses: [
        "0x49AD262C49C7aA708Cc2DF262eD53B64A17Dd5EE",
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      ],
    },
    collection: {
      name: "Bored Ape Kennel Club",
      imageUrl:
        "https://lh3.googleusercontent.com/9C9KKwWh9np4FtSlKoE4WWS6GjXpE8eSZ5LH_UV_qAE09Mrsn9O-fCSJcgE7r9XtBdf7ArCM6tgO0V4eHG9fVWQ5Z2fqCDMZDcI",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f274abb616dcc701c5cdb3",
    },
    transfer: {
      _id: {
        $oid: "65f274ab984769f352ee4489",
      },
      batch_transfer_index: 0,
      block_hash: "0x2aebbf19ae9a1b821d64647029583580e8e26fe6b0abb1ee0685f5cada5898a2",
      chain: "ethereum",
      log_index: 261,
      transaction: "0x33890bc16f1537d4c50a87de2c33c1a97b0b5850039d5e8ede0935aeca7f8919",
      block_number: 19430703,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.9071",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:52:59.000Z",
      },
      to_address: "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      token_id: "9071",
      transaction_initiator: "0x7cB6Ef54Ea9AE19DD769a1E5b5f8A6182b6cC5D1",
      transaction_to_address: "0x56Fb6A63314bD353Fc9568584DBD96Bf59b66e3B",
      upsertedAt: {
        $date: "2024-03-14T03:53:15.282Z",
      },
      walletAddresses: [
        "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
        "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f274abb616dcc701c5cdae",
    },
    transfer: {
      _id: {
        $oid: "65f274ab984769f352ee4488",
      },
      batch_transfer_index: 0,
      block_hash: "0x2aebbf19ae9a1b821d64647029583580e8e26fe6b0abb1ee0685f5cada5898a2",
      chain: "ethereum",
      log_index: 265,
      transaction: "0x33890bc16f1537d4c50a87de2c33c1a97b0b5850039d5e8ede0935aeca7f8919",
      block_number: 19430703,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.9821",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:52:59.000Z",
      },
      to_address: "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      token_id: "9821",
      transaction_initiator: "0x7cB6Ef54Ea9AE19DD769a1E5b5f8A6182b6cC5D1",
      transaction_to_address: "0x56Fb6A63314bD353Fc9568584DBD96Bf59b66e3B",
      upsertedAt: {
        $date: "2024-03-14T03:53:15.282Z",
      },
      walletAddresses: [
        "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
        "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f274abb616dcc701c5cdac",
    },
    transfer: {
      _id: {
        $oid: "65f274ab984769f352ee4488",
      },
      batch_transfer_index: 0,
      block_hash: "0x2aebbf19ae9a1b821d64647029583580e8e26fe6b0abb1ee0685f5cada5898a2",
      chain: "ethereum",
      log_index: 265,
      transaction: "0x33890bc16f1537d4c50a87de2c33c1a97b0b5850039d5e8ede0935aeca7f8919",
      block_number: 19430703,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.9821",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:52:59.000Z",
      },
      to_address: "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      token_id: "9821",
      transaction_initiator: "0x7cB6Ef54Ea9AE19DD769a1E5b5f8A6182b6cC5D1",
      transaction_to_address: "0x56Fb6A63314bD353Fc9568584DBD96Bf59b66e3B",
      upsertedAt: {
        $date: "2024-03-14T03:53:15.282Z",
      },
      walletAddresses: [
        "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
        "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f274aba312d3cb3f75f897",
    },
    transfer: {
      _id: {
        $oid: "65f274ab984769f352ee442d",
      },
      batch_transfer_index: 0,
      block_hash: "0x2aebbf19ae9a1b821d64647029583580e8e26fe6b0abb1ee0685f5cada5898a2",
      chain: "ethereum",
      log_index: 267,
      transaction: "0x33890bc16f1537d4c50a87de2c33c1a97b0b5850039d5e8ede0935aeca7f8919",
      block_number: 19430703,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.17138",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:52:59.000Z",
      },
      to_address: "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      token_id: "17138",
      transaction_initiator: "0x7cB6Ef54Ea9AE19DD769a1E5b5f8A6182b6cC5D1",
      transaction_to_address: "0x56Fb6A63314bD353Fc9568584DBD96Bf59b66e3B",
      upsertedAt: {
        $date: "2024-03-14T03:53:15.196Z",
      },
      walletAddresses: [
        "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
        "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f274aba312d3cb3f75f893",
    },
    transfer: {
      _id: {
        $oid: "65f274ab984769f352ee442c",
      },
      batch_transfer_index: 0,
      block_hash: "0x2aebbf19ae9a1b821d64647029583580e8e26fe6b0abb1ee0685f5cada5898a2",
      chain: "ethereum",
      log_index: 263,
      transaction: "0x33890bc16f1537d4c50a87de2c33c1a97b0b5850039d5e8ede0935aeca7f8919",
      block_number: 19430703,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.7554",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:52:59.000Z",
      },
      to_address: "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      token_id: "7554",
      transaction_initiator: "0x7cB6Ef54Ea9AE19DD769a1E5b5f8A6182b6cC5D1",
      transaction_to_address: "0x56Fb6A63314bD353Fc9568584DBD96Bf59b66e3B",
      upsertedAt: {
        $date: "2024-03-14T03:53:15.196Z",
      },
      walletAddresses: [
        "0x50d9090D6ce6307b7EC8904cD3dCa17b4Da56353",
        "0x15fA609e84905aFf86F02a9e29c9cd887b311a0c",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f274b1b616dcc701c5cdb7",
    },
    transfer: {
      _id: {
        $oid: "65f274b1984769f352ee46f3",
      },
      batch_transfer_index: 0,
      block_hash: "0x6aa4e61182a1f2c3a68f671451bdd25bc21e95e8a8075fb17326ec0beed11ccf",
      chain: "ethereum",
      log_index: 107,
      transaction: "0x789f0e06c8fa690e1437a809a25b187ecf9beef42fb64e49b2b5f94af85060ac",
      block_number: 19430704,
      collection_id: "bb5ff5dfae4816a2b0fa328f0649f6d6",
      contract_address: "0x4b15a9c28034dC83db40CD810001427d3BD7163D",
      event_type: "transfer",
      from_address: "0x9e048aD244f556736672BE0DfeC8f85aC7E5f01c",
      nft_id: "ethereum.0x4b15a9c28034dc83db40cd810001427d3bd7163d.12457",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:53:11.000Z",
      },
      to_address: "0x8D9E1222C4B6BE4efC989ffb179B6733679C3cA6",
      token_id: "12457",
      transaction_initiator: "0x9e048aD244f556736672BE0DfeC8f85aC7E5f01c",
      transaction_to_address: "0x4b15a9c28034dC83db40CD810001427d3BD7163D",
      upsertedAt: {
        $date: "2024-03-14T03:53:21.515Z",
      },
      walletAddresses: [
        "0x9e048aD244f556736672BE0DfeC8f85aC7E5f01c",
        "0x8D9E1222C4B6BE4efC989ffb179B6733679C3cA6",
      ],
    },
    collection: {
      name: "HV-MTL",
      imageUrl:
        "https://lh3.googleusercontent.com/bih_9O4P-5aGr4oBPigshNXW1hZudef9y1P9k4DbswpUhJSB7HCbdQuCtdUFKxwFFVwzfZ2TgWmKcsurfmdiGCqzCt9AnSW_ow",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f274faa2477ab2e40b4f5b",
    },
    transfer: {
      _id: {
        $oid: "65f274fa984769f352ee80bd",
      },
      batch_transfer_index: 0,
      block_hash: "0x6c74cb2cf62130df2f6250086f0edc4c373db7daa2d1d89ac63c88c655828d65",
      chain: "ethereum",
      log_index: 191,
      transaction: "0x394169003c0f2ac9fc0fb6007618c594167112e2585f1a15ab1e9086945b93c5",
      block_number: 19430710,
      collection_id: "bb5ff5dfae4816a2b0fa328f0649f6d6",
      contract_address: "0x4b15a9c28034dC83db40CD810001427d3BD7163D",
      event_type: "transfer",
      from_address: "0x9e048aD244f556736672BE0DfeC8f85aC7E5f01c",
      nft_id: "ethereum.0x4b15a9c28034dc83db40cd810001427d3bd7163d.9198",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:54:23.000Z",
      },
      to_address: "0x8D9E1222C4B6BE4efC989ffb179B6733679C3cA6",
      token_id: "9198",
      transaction_initiator: "0x9e048aD244f556736672BE0DfeC8f85aC7E5f01c",
      transaction_to_address: "0x4b15a9c28034dC83db40CD810001427d3BD7163D",
      upsertedAt: {
        $date: "2024-03-14T03:54:34.176Z",
      },
      walletAddresses: [
        "0x9e048aD244f556736672BE0DfeC8f85aC7E5f01c",
        "0x8D9E1222C4B6BE4efC989ffb179B6733679C3cA6",
      ],
    },
    collection: {
      name: "HV-MTL",
      imageUrl:
        "https://lh3.googleusercontent.com/bih_9O4P-5aGr4oBPigshNXW1hZudef9y1P9k4DbswpUhJSB7HCbdQuCtdUFKxwFFVwzfZ2TgWmKcsurfmdiGCqzCt9AnSW_ow",
    },
  },
  {
    isReceiver: false,
    filter: "Burn",
    _id: {
      $oid: "65f274fea312d3cb3f75f8a0",
    },
    transfer: {
      _id: {
        $oid: "65f274fe984769f352ee847d",
      },
      batch_transfer_index: 0,
      block_hash: "0x6c74cb2cf62130df2f6250086f0edc4c373db7daa2d1d89ac63c88c655828d65",
      chain: "ethereum",
      log_index: 475,
      transaction: "0x1361c10af798f37e06a3fcde2816ece27f9f6db2045a66bb6c7b874e5ac570b3",
      block_number: 19430710,
      collection_id: "faa8daac814f6e2d73f48d90f5a48b62",
      contract_address: "0x5b1085136a811e55b2Bb2CA1eA456bA82126A376",
      event_type: "burn",
      from_address: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      nft_id: "ethereum.0x5b1085136a811e55b2bb2ca1ea456ba82126a376.28057",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:54:23.000Z",
      },
      to_address: null,
      token_id: "28057",
      transaction_initiator: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      transaction_to_address: "0x3Bdca51226202Fc2a64211Aa35A8d95D61C6ca99",
      upsertedAt: {
        $date: "2024-03-14T03:54:38.196Z",
      },
      walletAddresses: ["0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09"],
    },
    collection: {
      name: "Otherside Vessels",
      imageUrl:
        "https://lh3.googleusercontent.com/5BFUXaf7S0dDdQOFdQQkPkK6unx5iDWtE7gIo4LpAka5IbaqgkRk6Ia58LMAonSycLgvv5om0MrFDN4VYNbri6LDHCXa5xjpNw",
    },
  },
  {
    isReceiver: true,
    filter: "Mint",
    _id: {
      $oid: "65f274fea2477ab2e40b4f5f",
    },
    transfer: {
      _id: {
        $oid: "65f274fe984769f352ee8584",
      },
      batch_transfer_index: 0,
      block_hash: "0x6c74cb2cf62130df2f6250086f0edc4c373db7daa2d1d89ac63c88c655828d65",
      chain: "ethereum",
      log_index: 474,
      transaction: "0x1361c10af798f37e06a3fcde2816ece27f9f6db2045a66bb6c7b874e5ac570b3",
      block_number: 19430710,
      collection_id: "dd27ec24781017fe4d214ec4193ea145",
      contract_address: "0x3Bdca51226202Fc2a64211Aa35A8d95D61C6ca99",
      event_type: "mint",
      from_address: null,
      nft_id: "ethereum.0x3bdca51226202fc2a64211aa35a8d95d61c6ca99.28057",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:54:23.000Z",
      },
      to_address: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      token_id: "28057",
      transaction_initiator: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      transaction_to_address: "0x3Bdca51226202Fc2a64211Aa35A8d95D61C6ca99",
      upsertedAt: {
        $date: "2024-03-14T03:54:38.911Z",
      },
      walletAddresses: ["0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09"],
    },
    collection: {
      name: "Otherside Mara",
      imageUrl:
        "https://lh3.googleusercontent.com/m4vwedAcv-J0iamg0aUaq0nPuK8MoU2TpgZs_nET48dSGtktBwhyUlb4V1HQgu2sQLWAWPPu-MPJw6H_2tOomCsgqLxYwDDXRQ",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27569a2477ab2e40b4f67",
    },
    transfer: {
      _id: {
        $oid: "65f27569984769f352eeb516",
      },
      batch_transfer_index: 0,
      block_hash: "0x925d6c839cd87d8e1c4038f46d4419232c1b85e8e6adc94c620346a5f634f900",
      chain: "ethereum",
      log_index: 265,
      transaction: "0x16d8ea959a350a7759ef1e048ebf2392c4f21e754024092726c25554df850364",
      block_number: 19430719,
      collection_id: "25be4e15d71b1dd97830dba4b9224e1c",
      contract_address: "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
      event_type: "transfer",
      from_address: "0xDf8B9d67BCa5392bdA67cC9A1F79f99f1B858a1F",
      nft_id: "ethereum.0xba30e5f9bb24caa003e9f2f0497ad287fdf95623.4375",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:56:11.000Z",
      },
      to_address: "0x859f4E44ed31931C51e618a6D6Ab2b9eEEDDE852",
      token_id: "4375",
      transaction_initiator: "0xDf8B9d67BCa5392bdA67cC9A1F79f99f1B858a1F",
      transaction_to_address: "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
      upsertedAt: {
        $date: "2024-03-14T03:56:25.438Z",
      },
      walletAddresses: [
        "0xDf8B9d67BCa5392bdA67cC9A1F79f99f1B858a1F",
        "0x859f4E44ed31931C51e618a6D6Ab2b9eEEDDE852",
      ],
    },
    collection: {
      name: "Bored Ape Kennel Club",
      imageUrl:
        "https://lh3.googleusercontent.com/9C9KKwWh9np4FtSlKoE4WWS6GjXpE8eSZ5LH_UV_qAE09Mrsn9O-fCSJcgE7r9XtBdf7ArCM6tgO0V4eHG9fVWQ5Z2fqCDMZDcI",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f275f5a312d3cb3f75f8a9",
    },
    transfer: {
      _id: {
        $oid: "65f275f5984769f352eee855",
      },
      batch_transfer_index: 0,
      block_hash: "0x9c4b066d8fe86b7c34d9cd076830b8495686d034580500a0e5dc61c012ff163b",
      chain: "ethereum",
      log_index: 36,
      transaction: "0xa26eea60dcf3489a6f236bb764487a1050057311d6b07b41d02d8c623156b1f9",
      block_number: 19430731,
      collection_id: "7c710ec52b7f00c4383268c02c7f07c1",
      contract_address: "0x1A92f7381B9F03921564a437210bB9396471050C",
      event_type: "sale",
      from_address: "0xc2079e5B077eae969dBEcA3747BB855Ae9DF8f3F",
      nft_id: "ethereum.0x1a92f7381b9f03921564a437210bb9396471050c.623",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "500000000000000000",
        },
        total_price: {
          $numberLong: "500000000000000000",
        },
        unit_price_usd_cents: 199340,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T03:58:35.000Z",
      },
      to_address: "0x0b4648Ea410f0a907107dd61b72b447DFefc0C10",
      token_id: "623",
      transaction_initiator: "0x0b4648Ea410f0a907107dd61b72b447DFefc0C10",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T03:58:45.788Z",
      },
      walletAddresses: [
        "0xc2079e5B077eae969dBEcA3747BB855Ae9DF8f3F",
        "0x0b4648Ea410f0a907107dd61b72b447DFefc0C10",
      ],
    },
    collection: {
      name: "Cool Cats",
      imageUrl:
        "https://lh3.googleusercontent.com/pCMTlkfx-5Oxf_Cl0vK5xMh8tshXbBBRB36KpLODcJSqtHmdR3hf4Gfx8RMQoDIQIPTv3xbtNtxA6w9wSeKwtmZVeJwjoFG63d0V",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f2761ea312d3cb3f75f8ad",
    },
    transfer: {
      _id: {
        $oid: "65f2761e984769f352eef7ff",
      },
      batch_transfer_index: 0,
      block_hash: "0xbd7b7f1fae49e65f861cff9aa97dd1ca4383ac0a3f05352899a2306c1ab34b01",
      chain: "ethereum",
      log_index: 348,
      transaction: "0x761f59a19d853cffddb2db64fbcc1ec7821397b8d68e7545665ca584c01c4661",
      block_number: 19430734,
      collection_id: "90a0171e030758cb4dbd5b4323319ef3",
      contract_address: "0x2358693f4FaEC9D658bB97Fc9cd8885F62105dc1",
      event_type: "sale",
      from_address: "0x05b890276a5F5dFD3Eb1c2D208f6dfc41FCc23e9",
      nft_id: "ethereum.0x2358693f4faec9d658bb97fc9cd8885f62105dc1.13263",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          alt_address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          decimals: 18,
          name: "Wrapped Ether",
          payment_token_id: "ethereum.0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
          symbol: "WETH",
        },
        unit_price: {
          $numberLong: "76000000000000000",
        },
        total_price: {
          $numberLong: "76000000000000000",
        },
        unit_price_usd_cents: 30300,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T03:59:11.000Z",
      },
      to_address: "0x8cd94dDd848563616A69434Fc37dE2CB56e8Fb4f",
      token_id: "13263",
      transaction_initiator: "0x05b890276a5F5dFD3Eb1c2D208f6dfc41FCc23e9",
      transaction_to_address: "0x5ebc127fae83ed5bdd91fc6a5f5767E259dF5642",
      upsertedAt: {
        $date: "2024-03-14T03:59:26.012Z",
      },
      walletAddresses: [
        "0x05b890276a5F5dFD3Eb1c2D208f6dfc41FCc23e9",
        "0x8cd94dDd848563616A69434Fc37dE2CB56e8Fb4f",
      ],
    },
    collection: {
      name: "10KTF G Tags",
      imageUrl:
        "https://lh3.googleusercontent.com/0qWyyMW5_6U1wtdLb5cyVoBqH8zm8-BO9GKZBnhPePaWN2m9qWOyeWLYXe9qAFmqfzJ0Zdq_VisIv3QIbnRau8g12wycb7Eg0EAp",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27635a312d3cb3f75f8ba",
    },
    transfer: {
      _id: {
        $oid: "65f27635984769f352ef015c",
      },
      batch_transfer_index: 0,
      block_hash: "0xdccdb0c720d29f6adb9c14a01e86666170a1adde63f8e2fe8a2feb045a382899",
      chain: "ethereum",
      log_index: 401,
      transaction: "0x6ec3e08e7e4f0268debce9b90818255aa558f387f0b2aa4cd4bfe72ef8c91aea",
      block_number: 19430736,
      collection_id: "2ccfd6cb165074e4881906dcc701f1d8",
      contract_address: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
      event_type: "transfer",
      from_address: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      nft_id: "ethereum.0xed5af388653567af2f388e6224dc7c4b3241c544.7208",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:59:35.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "7208",
      transaction_initiator: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T03:59:49.236Z",
      },
      walletAddresses: [
        "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Azuki",
      imageUrl:
        "https://lh3.googleusercontent.com/HTfDnsQfaqpkdPB0OITH5RSoAHKTUDYsB03S2jaClYMbnhhoTIB7u4Ln8QU01OvkJky1Tm1s6DsQtZMDqZcZ-zpy9lizOahrHA",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f276aaa2477ab2e40b4f76",
    },
    transfer: {
      _id: {
        $oid: "65f276aa984769f352ef234e",
      },
      batch_transfer_index: 0,
      block_hash: "0xf57634357bc209e66b71630eb97a031a7d3a7da8d283d2629cbbc8be8c5d55cb",
      chain: "ethereum",
      log_index: 62,
      transaction: "0x763e07f649b3f03951aaf3e8774eab973f52312667963d32b65319b365eb48f5",
      block_number: 19430746,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.11578",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "2295000000000000000",
        },
        total_price: {
          $numberLong: "2295000000000000000",
        },
        unit_price_usd_cents: 916726,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:01:35.000Z",
      },
      to_address: "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      token_id: "11578",
      transaction_initiator: "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:01:46.435Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f27770a312d3cb3f75f8cf",
    },
    transfer: {
      _id: {
        $oid: "65f27770984769f352efa5fd",
      },
      batch_transfer_index: 0,
      block_hash: "0x6077d0c0d31f5032a7d2416c34860cbd0d68a6e9e8462da8434cd0ffd4d4f09b",
      chain: "ethereum",
      log_index: 143,
      transaction: "0x56b4fa821a5c4b8b920bf4bfc432929f9905a2230193ec65391caf13c6c6f1ea",
      block_number: 19430762,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.24084",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "2260000000000000000",
        },
        total_price: {
          $numberLong: "2260000000000000000",
        },
        unit_price_usd_cents: 902746,
        fees: [],
      },
      timestamp: {
        $date: "2024-03-14T04:04:47.000Z",
      },
      to_address: "0x1eCbC285F34390516FB69797949D9d4Ca3F86CFf",
      token_id: "24084",
      transaction_initiator: "0xfF0b9681ef40Fd0992Ef52fD5bd94E0Fa21c0359",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:05:04.529Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x1eCbC285F34390516FB69797949D9d4Ca3F86CFf",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f277afa312d3cb3f75f8d3",
    },
    transfer: {
      _id: {
        $oid: "65f277af984769f352efbbcb",
      },
      batch_transfer_index: 0,
      block_hash: "0xb1c0cf9d1b5be9f30d91495717714268efd22b439c44a1b08079eaab7edfa9e9",
      chain: "ethereum",
      log_index: 158,
      transaction: "0xcd8556cdd5c3e2ac387a74ad75e11286cf85ca12fb916200b81e6db0f3050c84",
      block_number: 19430768,
      collection_id: "fd80222e34a61337ce149babb43f98ce",
      contract_address: "0xd668A2E001f3385B8BBC5a8682AC3C0D83C19122",
      event_type: "transfer",
      from_address: "0x1f4598Dbe53a8231e06e264DDfB3Ea25051baEE6",
      nft_id: "ethereum.0xd668a2e001f3385b8bbc5a8682ac3c0d83c19122.7282",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:05:59.000Z",
      },
      to_address: "0xF75E0Cb0f1e2838606934dE0AC9a657C5f4b5767",
      token_id: "7282",
      transaction_initiator: "0x1f4598Dbe53a8231e06e264DDfB3Ea25051baEE6",
      transaction_to_address: "0xd668A2E001f3385B8BBC5a8682AC3C0D83C19122",
      upsertedAt: {
        $date: "2024-03-14T04:06:07.921Z",
      },
      walletAddresses: [
        "0x1f4598Dbe53a8231e06e264DDfB3Ea25051baEE6",
        "0xF75E0Cb0f1e2838606934dE0AC9a657C5f4b5767",
      ],
    },
    collection: {
      name: "KingFrogs",
      imageUrl:
        "https://lh3.googleusercontent.com/VRxXZ7QG104UfGMl32CVkQf3qnxJF1Ud5-z8X-A7Z54Z4rQMD_4jzdhc4E-gd2j_0BfkKVx_96Jx7PHdi7Uv1z2lgkViAKZpUA",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f277cba312d3cb3f75f8d8",
    },
    transfer: {
      _id: {
        $oid: "65f277cb984769f352efc843",
      },
      batch_transfer_index: 0,
      block_hash: "0x56858f38d3b569441cb340e19f3df2e641ce119c1e41ef3728d56114a8421691",
      chain: "ethereum",
      log_index: 292,
      transaction: "0x767522ba83be416f4fd498fa91db1147f815fcacb84aaa4aa51590b256a7053f",
      block_number: 19430770,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.1491",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:06:23.000Z",
      },
      to_address: "0xcF749c020f1d187c199fb0533d59f0DC3B40f798",
      token_id: "1491",
      transaction_initiator: "0xcF749c020f1d187c199fb0533d59f0DC3B40f798",
      transaction_to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      upsertedAt: {
        $date: "2024-03-14T04:06:35.935Z",
      },
      walletAddresses: [
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
        "0xcF749c020f1d187c199fb0533d59f0DC3B40f798",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f277d5b616dcc701c5cdd4",
    },
    transfer: {
      _id: {
        $oid: "65f277d5984769f352efcc37",
      },
      batch_transfer_index: 0,
      block_hash: "0x623022ad80682ae3ed54e9f6ba08aaa9dc569af1a523bb6004620cf495d3ca79",
      chain: "ethereum",
      log_index: 174,
      transaction: "0x1387130ae75f64b3a0a8c9cfb248eff2db617ece73a3f1d19ea50e2d8bbbab9a",
      block_number: 19430771,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.4994",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1332700000000000000",
        },
        total_price: {
          $numberLong: "1332700000000000000",
        },
        unit_price_usd_cents: 532340,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:06:35.000Z",
      },
      to_address: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      token_id: "4994",
      transaction_initiator: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:06:45.845Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f277ffa312d3cb3f75f8e6",
    },
    transfer: {
      _id: {
        $oid: "65f277ff984769f352efd9ec",
      },
      batch_transfer_index: 0,
      block_hash: "0x9f0e65d60a990b773f89a98f55fe879c2bd40d7ef13892291c9df91ccc9eb291",
      chain: "ethereum",
      log_index: 606,
      transaction: "0x4ae135922620b823b10db565302f3ebe15992c25a9d842f0b8459c40c41331f7",
      block_number: 19430774,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.3690",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1330000000000000000",
        },
        total_price: {
          $numberLong: "1330000000000000000",
        },
        unit_price_usd_cents: 531262,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:07:11.000Z",
      },
      to_address: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      token_id: "3690",
      transaction_initiator: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:07:27.818Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f2784ea312d3cb3f75f8f5",
    },
    transfer: {
      _id: {
        $oid: "65f2784e984769f352f03512",
      },
      batch_transfer_index: 0,
      block_hash: "0x91f29b9b5441b1909733759fbb0972f362ba0e6240e04c809dfa2d378d53d041",
      chain: "ethereum",
      log_index: 272,
      transaction: "0xcc9c103646e80ebef283f638483b51fd32e92c7e8cc0b3318aeac2e55210e091",
      block_number: 19430780,
      collection_id: "0428b0b01123e7fca12035195f7373fb",
      contract_address: "0x6c410cF0B8c113Dc6A7641b431390B11d5515082",
      event_type: "sale",
      from_address: "0x4Dec2e09C0c427aEf7c42d565dF3C58898e8514c",
      nft_id: "ethereum.0x6c410cf0b8c113dc6a7641b431390b11d5515082.5023",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "110000000000000000",
        },
        total_price: {
          $numberLong: "110000000000000000",
        },
        unit_price_usd_cents: 43939,
        fees: [
          {
            type: "royalty",
            bps: 1000,
            recipient: "0xbeb5825772B52811C23a1389A391934AE71808aC",
            amount: "11000000000000000",
          },
        ],
      },
      timestamp: {
        $date: "2024-03-14T04:08:23.000Z",
      },
      to_address: "0x5B8E81902Cfe4C30Cb50c7804FC62500AbB7CfAC",
      token_id: "5023",
      transaction_initiator: "0x4Dec2e09C0c427aEf7c42d565dF3C58898e8514c",
      transaction_to_address: "0xb2ecfE4E4D61f8790bbb9DE2D1259B9e2410CEA5",
      upsertedAt: {
        $date: "2024-03-14T04:08:46.799Z",
      },
      walletAddresses: [
        "0x4Dec2e09C0c427aEf7c42d565dF3C58898e8514c",
        "0x5B8E81902Cfe4C30Cb50c7804FC62500AbB7CfAC",
      ],
    },
    collection: {
      name: "RTFKT Animus Egg 🥚",
      imageUrl:
        "https://lh3.googleusercontent.com/mDRxAUIRE2WaYD4IjIlSLvZE-6x21wDaiUU63gGjL8M524oJv5ejkYwN992U_yYHpENTpXuegR5HPhLpM3lBnpUvfxKsvYcIqvM",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f27851a2477ab2e40b4f89",
    },
    transfer: {
      _id: {
        $oid: "65f27851984769f352f03609",
      },
      batch_transfer_index: 0,
      block_hash: "0x9b92307a1eb9af01174ef96abf805a7e4297e3e54c3dbe769f9a47b868f2e327",
      chain: "ethereum",
      log_index: 193,
      transaction: "0x20b6b77d035d01e53804104214928ab0ad965d3696e05d420105a4bc4cb4ab60",
      block_number: 19430781,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.5921",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1330000000000000000",
        },
        total_price: {
          $numberLong: "1330000000000000000",
        },
        unit_price_usd_cents: 531262,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:08:35.000Z",
      },
      to_address: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      token_id: "5921",
      transaction_initiator: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:08:49.286Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2785ea312d3cb3f75f8fd",
    },
    transfer: {
      _id: {
        $oid: "65f2785e984769f352f039ca",
      },
      batch_transfer_index: 0,
      block_hash: "0x2ca9b34bc60808fbb98cd47cb75a32a3eccee2906b1cd2e469905078d4102547",
      chain: "ethereum",
      log_index: 237,
      transaction: "0x53dd8e120f7265d3248b2691f15a59ea11f100025615a936b4c80d4a815093b9",
      block_number: 19430782,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.2646",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:08:47.000Z",
      },
      to_address: "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      token_id: "2646",
      transaction_initiator: "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      transaction_to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      upsertedAt: {
        $date: "2024-03-14T04:09:02.345Z",
      },
      walletAddresses: [
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
        "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2785da2477ab2e40b4f8f",
    },
    transfer: {
      _id: {
        $oid: "65f2785d984769f352f0395c",
      },
      batch_transfer_index: 0,
      block_hash: "0x2ca9b34bc60808fbb98cd47cb75a32a3eccee2906b1cd2e469905078d4102547",
      chain: "ethereum",
      log_index: 236,
      transaction: "0x53dd8e120f7265d3248b2691f15a59ea11f100025615a936b4c80d4a815093b9",
      block_number: 19430782,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.878",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:08:47.000Z",
      },
      to_address: "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      token_id: "878",
      transaction_initiator: "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      transaction_to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      upsertedAt: {
        $date: "2024-03-14T04:09:01.701Z",
      },
      walletAddresses: [
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
        "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f2787ba2477ab2e40b4f9d",
    },
    transfer: {
      _id: {
        $oid: "65f2787b984769f352f040fd",
      },
      batch_transfer_index: 0,
      block_hash: "0xf4dc3ffb0adfde5a24cf47b53c8c6c3530033fa6148c5530f880950b6ff0025f",
      chain: "ethereum",
      log_index: 354,
      transaction: "0x472b03eff92e6485e7a3b67f2c30fd062c439351a3517f65cfdde3b58923dd23",
      block_number: 19430784,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.27446",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "2260000000000000000",
        },
        total_price: {
          $numberLong: "2260000000000000000",
        },
        unit_price_usd_cents: 902746,
        fees: [],
      },
      timestamp: {
        $date: "2024-03-14T04:09:11.000Z",
      },
      to_address: "0x9aFeF7dac35f070Dc3976F0597B8C872F6b19D1d",
      token_id: "27446",
      transaction_initiator: "0xfF0b9681ef40Fd0992Ef52fD5bd94E0Fa21c0359",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:09:31.028Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x9aFeF7dac35f070Dc3976F0597B8C872F6b19D1d",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f27880a312d3cb3f75f90e",
    },
    transfer: {
      _id: {
        $oid: "65f27880984769f352f041d6",
      },
      batch_transfer_index: 0,
      block_hash: "0x9c954978c03b000b7111f269acafecefeb6e30878cb622728864db5e23d832ba",
      chain: "ethereum",
      log_index: 212,
      transaction: "0xfc87591849ff33c8ff348d7aee0cad90f60fdabeefc9dc512ce7cf653bfd53b8",
      block_number: 19430785,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.9192",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1333000000000000000",
        },
        total_price: {
          $numberLong: "1333000000000000000",
        },
        unit_price_usd_cents: 532460,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:09:23.000Z",
      },
      to_address: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      token_id: "9192",
      transaction_initiator: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:09:36.240Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f27888a2477ab2e40b4fa8",
    },
    transfer: {
      _id: {
        $oid: "65f27888984769f352f043aa",
      },
      batch_transfer_index: 0,
      block_hash: "0x9c954978c03b000b7111f269acafecefeb6e30878cb622728864db5e23d832ba",
      chain: "ethereum",
      log_index: 474,
      transaction: "0x36962bc9dabfb5a26525a7589be05a891f11fa30d112d5de4af0b4730111dfc7",
      block_number: 19430785,
      collection_id: "a260125ee1a6b2b0719e961c4cdc1ec8",
      contract_address: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b.6513",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "540000000000000000",
        },
        total_price: {
          $numberLong: "540000000000000000",
        },
        unit_price_usd_cents: 215700,
        fees: [],
      },
      timestamp: {
        $date: "2024-03-14T04:09:23.000Z",
      },
      to_address: "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      token_id: "6513",
      transaction_initiator: "0x8BC25fb0b16bba927976554F03653D7865801620",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:09:44.133Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      ],
    },
    collection: {
      name: "CLONE X - X TAKASHI MURAKAMI",
      imageUrl:
        "https://lh3.googleusercontent.com/21TYFeoq3hTDKWUJhtIXthkyaLr2WzU9s9U9-n6VRt2xpCxFZkNQDVegbAknE0ZwrIQj0VwcPa3ci5jBrqxmM9HQ-9mDJlFJVKF9",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f2788db616dcc701c5cde1",
    },
    transfer: {
      _id: {
        $oid: "65f2788d984769f352f045f4",
      },
      batch_transfer_index: 0,
      block_hash: "0x27d5bc714ffedcff288aed5154bbdec0383de005cd327ff621ce491639c1dfb5",
      chain: "ethereum",
      log_index: 246,
      transaction: "0xa29ea9646d134452681c492c1664004c8bed944386ec618af581a974ccdebe63",
      block_number: 19430786,
      collection_id: "a260125ee1a6b2b0719e961c4cdc1ec8",
      contract_address: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b.18370",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "540000000000000000",
        },
        total_price: {
          $numberLong: "540000000000000000",
        },
        unit_price_usd_cents: 215700,
        fees: [],
      },
      timestamp: {
        $date: "2024-03-14T04:09:35.000Z",
      },
      to_address: "0x954d65e5310607e12F93f6156de92cD37FFcae8e",
      token_id: "18370",
      transaction_initiator: "0x8BC25fb0b16bba927976554F03653D7865801620",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:09:49.468Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x954d65e5310607e12F93f6156de92cD37FFcae8e",
      ],
    },
    collection: {
      name: "CLONE X - X TAKASHI MURAKAMI",
      imageUrl:
        "https://lh3.googleusercontent.com/21TYFeoq3hTDKWUJhtIXthkyaLr2WzU9s9U9-n6VRt2xpCxFZkNQDVegbAknE0ZwrIQj0VwcPa3ci5jBrqxmM9HQ-9mDJlFJVKF9",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f278a4b616dcc701c5cdee",
    },
    transfer: {
      _id: {
        $oid: "65f278a4984769f352f04bb0",
      },
      batch_transfer_index: 0,
      block_hash: "0x05f91159263f9ce7b1fdb6f96d31c2cf21a886e1ae1a333ff6a763fbb43cbfff",
      chain: "ethereum",
      log_index: 177,
      transaction: "0xe75c5951eb4773c11f338f366626729afca7d5489c396a6a55de1258ab59fc61",
      block_number: 19430788,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.4685",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1378890000000000000",
        },
        total_price: {
          $numberLong: "1378890000000000000",
        },
        unit_price_usd_cents: 550791,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:09:59.000Z",
      },
      to_address: "0xA997faA3EE748067d1D6E17e16333DD8Ad29535e",
      token_id: "4685",
      transaction_initiator: "0xA997faA3EE748067d1D6E17e16333DD8Ad29535e",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:10:12.552Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xA997faA3EE748067d1D6E17e16333DD8Ad29535e",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f278c9a312d3cb3f75f917",
    },
    transfer: {
      _id: {
        $oid: "65f278c9984769f352f0553b",
      },
      batch_transfer_index: 0,
      block_hash: "0x4fc9527ccc2128eae1ab1a17586215c8621d415915ea6f681b4623a175d9ae14",
      chain: "ethereum",
      log_index: 83,
      transaction: "0xf3d79bff7514e5c5f439a281c76a1f3cdefa441a66196a60ae52156256daadb2",
      block_number: 19430791,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.4006",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1344000000000000000",
        },
        total_price: {
          $numberLong: "1344000000000000000",
        },
        unit_price_usd_cents: 536854,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:10:35.000Z",
      },
      to_address: "0x3d0202E0bB6d8Df16f1960329B30151d8b847D6b",
      token_id: "4006",
      transaction_initiator: "0x3d0202E0bB6d8Df16f1960329B30151d8b847D6b",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:10:49.277Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x3d0202E0bB6d8Df16f1960329B30151d8b847D6b",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f278d6a312d3cb3f75f925",
    },
    transfer: {
      _id: {
        $oid: "65f278d6984769f352f05b65",
      },
      batch_transfer_index: 0,
      block_hash: "0x14545f35fdf385ca09c2222d96273b2b7e84083b14f900e1d50244be2fc60843",
      chain: "ethereum",
      log_index: 74,
      transaction: "0x8b1fb7940aaad9e8b333f5fc44583354d00bf38fc46bcf7402b61322b724d6f1",
      block_number: 19430792,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.4738",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1332700000000000000",
        },
        total_price: {
          $numberLong: "1332700000000000000",
        },
        unit_price_usd_cents: 532340,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:10:47.000Z",
      },
      to_address: "0x3d0202E0bB6d8Df16f1960329B30151d8b847D6b",
      token_id: "4738",
      transaction_initiator: "0x3d0202E0bB6d8Df16f1960329B30151d8b847D6b",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:11:02.756Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x3d0202E0bB6d8Df16f1960329B30151d8b847D6b",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f27956a2477ab2e40b4fb1",
    },
    transfer: {
      _id: {
        $oid: "65f27956984769f352f0811c",
      },
      batch_transfer_index: 0,
      block_hash: "0xf65ff4df28240cad5b712067eb26091f150aad3c5ad65f62885b4c60cca7e726",
      chain: "ethereum",
      log_index: 265,
      transaction: "0xcdadab87df221d46fdbb9f46c4f269d63d96bea114fabc1146f408f39fe26c4b",
      block_number: 19430803,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.6895",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:12:59.000Z",
      },
      to_address: "0xCf3Ed0Bc9579b15c05fE95211dADF1caE0446EE8",
      token_id: "6895",
      transaction_initiator: "0xCf3Ed0Bc9579b15c05fE95211dADF1caE0446EE8",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:13:10.832Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xCf3Ed0Bc9579b15c05fE95211dADF1caE0446EE8",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f2795da312d3cb3f75f92a",
    },
    transfer: {
      _id: {
        $oid: "65f2795d984769f352f08306",
      },
      batch_transfer_index: 0,
      block_hash: "0xf65ff4df28240cad5b712067eb26091f150aad3c5ad65f62885b4c60cca7e726",
      chain: "ethereum",
      log_index: 370,
      transaction: "0x5d144ea24147edc7c78431e3ccf080f38c9e728c16471ae8c1a9c845b340106c",
      block_number: 19430803,
      collection_id: "a39c3ba0c14a87f93a2efcbfc20b29b3",
      contract_address: "0x70bE4E3761188d0a8c525E54bB81c4Ea97712de4",
      event_type: "sale",
      from_address: "0x52e79b810e3801f586e07bbEDa57d942D01056bC",
      nft_id: "ethereum.0x70be4e3761188d0a8c525e54bb81c4ea97712de4.2423",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "62000000000000000",
        },
        total_price: {
          $numberLong: "62000000000000000",
        },
        unit_price_usd_cents: 24766,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:12:59.000Z",
      },
      to_address: "0x72703B554A7089F93Ff1fC6CC6c0e623900a7b80",
      token_id: "2423",
      transaction_initiator: "0x72703B554A7089F93Ff1fC6CC6c0e623900a7b80",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T04:13:17.119Z",
      },
      walletAddresses: [
        "0x52e79b810e3801f586e07bbEDa57d942D01056bC",
        "0x72703B554A7089F93Ff1fC6CC6c0e623900a7b80",
      ],
    },
    collection: {
      name: "Moonshot by Violetta Zironi",
      imageUrl:
        "https://lh3.googleusercontent.com/P3mZ7yGdDdMEGaoyRGWaKPUvGfHHjqXHrV0SuXlh9-MrX6rDIegx1WwlMk9N6tiio6PhVhVZqqOTjzJ-eMv4vdxWSkwZ14zBZw4",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2795ba2477ab2e40b4fc2",
    },
    transfer: {
      _id: {
        $oid: "65f2795b984769f352f082b1",
      },
      batch_transfer_index: 0,
      block_hash: "0xf65ff4df28240cad5b712067eb26091f150aad3c5ad65f62885b4c60cca7e726",
      chain: "ethereum",
      log_index: 262,
      transaction: "0xb1b274bc746dbc4bbb4b36c9f846c85e9e26461d4d9f187dad8fa4c32b566fad",
      block_number: 19430803,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.1547",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:12:59.000Z",
      },
      to_address: "0x2AFeC1C9af7A5494503F8aCFD5C1fdd7D2C57480",
      token_id: "1547",
      transaction_initiator: "0x2AFeC1C9af7A5494503F8aCFD5C1fdd7D2C57480",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:13:15.544Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x2AFeC1C9af7A5494503F8aCFD5C1fdd7D2C57480",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f279b6a2477ab2e40b4fc5",
    },
    transfer: {
      _id: {
        $oid: "65f279b6984769f352f09f4e",
      },
      batch_transfer_index: 0,
      block_hash: "0x55c41188228b7e14044769ac956a05814bc36509bb3fc7ac4fd50182db788e7f",
      chain: "ethereum",
      log_index: 343,
      transaction: "0xb1b2f52addb6ee7506b6452c96f6956f4645ec2670e9aca0edf3f18dc3182bb9",
      block_number: 19430811,
      collection_id: "c2153e3ef733fa2bdb6c320d2dd0e72d",
      contract_address: "0x524cAB2ec69124574082676e6F654a18df49A048",
      event_type: "sale",
      from_address: "0x6c88FF514c8851fcE4c23F8820D8fAcE5Fa20d3d",
      nft_id: "ethereum.0x524cab2ec69124574082676e6f654a18df49a048.5779",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "1135000000000000000",
        },
        total_price: {
          $numberLong: "1135000000000000000",
        },
        unit_price_usd_cents: 453370,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:14:35.000Z",
      },
      to_address: "0x771c82DD5c6e1011adcF4372E77cf5E5587A9054",
      token_id: "5779",
      transaction_initiator: "0x771c82DD5c6e1011adcF4372E77cf5E5587A9054",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T04:14:46.712Z",
      },
      walletAddresses: [
        "0x6c88FF514c8851fcE4c23F8820D8fAcE5Fa20d3d",
        "0x771c82DD5c6e1011adcF4372E77cf5E5587A9054",
      ],
    },
    collection: {
      name: "Lil Pudgys",
      imageUrl:
        "https://lh3.googleusercontent.com/fJQyqrNtVNHnAe7jtexk2DcoXx4BIyNf-EbPWby0cAerkTGpvCEk-OcT-wK9Fus3cUyw-E6KOlNqEgilDOgAOYoS6shUmKXbXA",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f279c1b616dcc701c5cdf9",
    },
    transfer: {
      _id: {
        $oid: "65f279c1984769f352f0a29f",
      },
      batch_transfer_index: 0,
      block_hash: "0x29f1c548a4c911b34f8daaf43b1e0a57b1ee20abc2fc986d958ed09fbbfa22d9",
      chain: "ethereum",
      log_index: 201,
      transaction: "0x14075d2c5fcc378b6e9b2ccd26070bf8f119871595d6928f79c46a417f90dee1",
      block_number: 19430812,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.5036",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1353000000000000000",
        },
        total_price: {
          $numberLong: "1353000000000000000",
        },
        unit_price_usd_cents: 540449,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:14:47.000Z",
      },
      to_address: "0xb2ED4f7d835C2e638f5B68a49666Acc21c34818e",
      token_id: "5036",
      transaction_initiator: "0xb2ED4f7d835C2e638f5B68a49666Acc21c34818e",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:14:57.835Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xb2ED4f7d835C2e638f5B68a49666Acc21c34818e",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f27a2da312d3cb3f75f930",
    },
    transfer: {
      _id: {
        $oid: "65f27a2d984769f352f0c3a2",
      },
      batch_transfer_index: 0,
      block_hash: "0x9604f608bef0e1e063d51d5536546e80082b55d8fddc9327e2feef0aafef6f7a",
      chain: "ethereum",
      log_index: 262,
      transaction: "0xa02b65c1c485c9345d0eed632edbc7880b3e0d7a94fe6ea450eabb2f6e5b8a74",
      block_number: 19430821,
      collection_id: "82a5f5bc77f71a0ea50a4e615f75cbda",
      contract_address: "0x7E6027a6A84fC1F6Db6782c523EFe62c923e46ff",
      event_type: "sale",
      from_address: "0x965401cB606e9F4233444dc27b856B743831bB02",
      nft_id:
        "ethereum.0x7e6027a6a84fc1f6db6782c523efe62c923e46ff.73560007365330060493330970373703497531984544702360432858585716732871015784911",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          alt_address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          decimals: 18,
          name: "Wrapped Ether",
          payment_token_id: "ethereum.0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
          symbol: "WETH",
        },
        unit_price: {
          $numberLong: "180000000000000000",
        },
        total_price: {
          $numberLong: "180000000000000000",
        },
        unit_price_usd_cents: 71900,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:16:35.000Z",
      },
      to_address: "0x3dBC93AEcD50e485ecD2Dd6DBE73d1eC19A4d839",
      token_id: "73560007365330060493330970373703497531984544702360432858585716732871015784911",
      transaction_initiator: "0x965401cB606e9F4233444dc27b856B743831bB02",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T04:16:45.322Z",
      },
      walletAddresses: [
        "0x965401cB606e9F4233444dc27b856B743831bB02",
        "0x3dBC93AEcD50e485ecD2Dd6DBE73d1eC19A4d839",
      ],
    },
    collection: {
      name: "Rare Pepe (2016 - 2018)",
      imageUrl:
        "https://lh3.googleusercontent.com/wCOY9wETw8gB52SIjCJu4cKQtKD2uYlAZPRmDAt_iLRL0jWczOH97xRweykUhB41oPqq0LYvsHRDnpeFWUZ0dvzLZ-DSxVvAKvIn",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f27b46b616dcc701c5ce06",
    },
    transfer: {
      _id: {
        $oid: "65f27b46984769f352f14d83",
      },
      batch_transfer_index: 0,
      block_hash: "0x83b47bc555ed2ad0835c853d7e280866ca629102a85575dfc3e051b98a6eb245",
      chain: "ethereum",
      log_index: 185,
      transaction: "0x58932f299fba75e7ff8eb89acfc4ab3b9f6fb708ee227947570f08c0e1e0e27b",
      block_number: 19430842,
      collection_id: "510139ec0d8cf5513d59a8d3afa1c061",
      contract_address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
      event_type: "transfer",
      from_address: "0xB81965DdFdDA3923f292a47A1be83ba3A36B5133",
      nft_id: "ethereum.0x8a90cab2b38dba80c64b7734e58ee1db38b8992e.8713",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:20:59.000Z",
      },
      to_address: "0x064980edD7D43AbEE781f49A4e31C06Df05EcccB",
      token_id: "8713",
      transaction_initiator: "0x064980edD7D43AbEE781f49A4e31C06Df05EcccB",
      transaction_to_address: "0xB81965DdFdDA3923f292a47A1be83ba3A36B5133",
      upsertedAt: {
        $date: "2024-03-14T04:21:26.799Z",
      },
      walletAddresses: [
        "0xB81965DdFdDA3923f292a47A1be83ba3A36B5133",
        "0x064980edD7D43AbEE781f49A4e31C06Df05EcccB",
      ],
    },
    collection: {
      name: "Doodles",
      imageUrl:
        "https://lh3.googleusercontent.com/2tqe0lUsBXzDDZGfv7KNjk2JrDeyMH4MdSDRoH8WF6APxWXQuSCVGiNkmJACLchXiwJZ8kgXu0X7RclZ3kOwLv-K29Hsuq3u9qA",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27b46b616dcc701c5ce0a",
    },
    transfer: {
      _id: {
        $oid: "65f27b46984769f352f14d83",
      },
      batch_transfer_index: 0,
      block_hash: "0x83b47bc555ed2ad0835c853d7e280866ca629102a85575dfc3e051b98a6eb245",
      chain: "ethereum",
      log_index: 185,
      transaction: "0x58932f299fba75e7ff8eb89acfc4ab3b9f6fb708ee227947570f08c0e1e0e27b",
      block_number: 19430842,
      collection_id: "510139ec0d8cf5513d59a8d3afa1c061",
      contract_address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
      event_type: "transfer",
      from_address: "0xB81965DdFdDA3923f292a47A1be83ba3A36B5133",
      nft_id: "ethereum.0x8a90cab2b38dba80c64b7734e58ee1db38b8992e.8713",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:20:59.000Z",
      },
      to_address: "0x064980edD7D43AbEE781f49A4e31C06Df05EcccB",
      token_id: "8713",
      transaction_initiator: "0x064980edD7D43AbEE781f49A4e31C06Df05EcccB",
      transaction_to_address: "0xB81965DdFdDA3923f292a47A1be83ba3A36B5133",
      upsertedAt: {
        $date: "2024-03-14T04:21:26.799Z",
      },
      walletAddresses: [
        "0xB81965DdFdDA3923f292a47A1be83ba3A36B5133",
        "0x064980edD7D43AbEE781f49A4e31C06Df05EcccB",
      ],
    },
    collection: {
      name: "Doodles",
      imageUrl:
        "https://lh3.googleusercontent.com/2tqe0lUsBXzDDZGfv7KNjk2JrDeyMH4MdSDRoH8WF6APxWXQuSCVGiNkmJACLchXiwJZ8kgXu0X7RclZ3kOwLv-K29Hsuq3u9qA",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f27b6fa312d3cb3f75f93a",
    },
    transfer: {
      _id: {
        $oid: "65f27b6f984769f352f155ae",
      },
      batch_transfer_index: 0,
      block_hash: "0x05ec716b50bf6a0d5098a75ce87454537475778cd6d4771f713f32085be951eb",
      chain: "ethereum",
      log_index: 636,
      transaction: "0xba3d55f30f7c200d8e8270c5125054a67e8f23b4014a3a2bc34169a3ff2abe3f",
      block_number: 19430846,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.9823",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:21:47.000Z",
      },
      to_address: "0x2AFeC1C9af7A5494503F8aCFD5C1fdd7D2C57480",
      token_id: "9823",
      transaction_initiator: "0x2AFeC1C9af7A5494503F8aCFD5C1fdd7D2C57480",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:22:07.714Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x2AFeC1C9af7A5494503F8aCFD5C1fdd7D2C57480",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f27c14a312d3cb3f75f951",
    },
    transfer: {
      _id: {
        $oid: "65f27c14984769f352f17cbc",
      },
      batch_transfer_index: 0,
      block_hash: "0x6b09ec44a6eeb17878f351c8d17099c654e382e0c262cee41a0b72c24178e6ee",
      chain: "ethereum",
      log_index: 151,
      transaction: "0xa47c3de3d6ffbb60e4f740082ff6ed48347b9cb8a1ffa3ef64bcded7c27bd4b6",
      block_number: 19430859,
      collection_id: "c2153e3ef733fa2bdb6c320d2dd0e72d",
      contract_address: "0x524cAB2ec69124574082676e6F654a18df49A048",
      event_type: "sale",
      from_address: "0x74a611163e4197d97c3FB71a84EA5164783396e8",
      nft_id: "ethereum.0x524cab2ec69124574082676e6f654a18df49a048.2504",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1060000000000000000",
        },
        total_price: {
          $numberLong: "1060000000000000000",
        },
        unit_price_usd_cents: 423412,
        fees: [
          {
            type: "royalty",
            bps: 50,
            recipient: "0x2dE038A402119bCbd49A4412c35f27670801eD4e",
            amount: "5300000000000000",
          },
        ],
      },
      timestamp: {
        $date: "2024-03-14T04:24:23.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "2504",
      transaction_initiator: "0x7f919B2278D19ff805A09d3F4D598D8130E88593",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:24:52.460Z",
      },
      walletAddresses: [
        "0x74a611163e4197d97c3FB71a84EA5164783396e8",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Lil Pudgys",
      imageUrl:
        "https://lh3.googleusercontent.com/fJQyqrNtVNHnAe7jtexk2DcoXx4BIyNf-EbPWby0cAerkTGpvCEk-OcT-wK9Fus3cUyw-E6KOlNqEgilDOgAOYoS6shUmKXbXA",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c90b616dcc701c5ce21",
    },
    transfer: {
      _id: {
        $oid: "65f27c90984769f352f1d8a0",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 95,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1212",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1212",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:56.514Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c91a312d3cb3f75f976",
    },
    transfer: {
      _id: {
        $oid: "65f27c91984769f352f1d92f",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 100,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1210",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1210",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:57.151Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c91a312d3cb3f75f972",
    },
    transfer: {
      _id: {
        $oid: "65f27c91984769f352f1d92d",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 93,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1227",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1227",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:57.151Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c91a312d3cb3f75f96e",
    },
    transfer: {
      _id: {
        $oid: "65f27c91984769f352f1d92c",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 97,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1216",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1216",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:57.151Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c91a2477ab2e40b4fd8",
    },
    transfer: {
      _id: {
        $oid: "65f27c91984769f352f1d902",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 96,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1224",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1224",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:57.067Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c91a2477ab2e40b4fd4",
    },
    transfer: {
      _id: {
        $oid: "65f27c91984769f352f1d901",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 92,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1229",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1229",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:57.067Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c90b616dcc701c5ce25",
    },
    transfer: {
      _id: {
        $oid: "65f27c90984769f352f1d8a3",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 91,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1230",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1230",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:56.514Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c90b616dcc701c5ce1d",
    },
    transfer: {
      _id: {
        $oid: "65f27c90984769f352f1d89f",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 99,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1207",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1207",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:56.514Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c90a312d3cb3f75f961",
    },
    transfer: {
      _id: {
        $oid: "65f27c90984769f352f1d864",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 98,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1220",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1220",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:56.060Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27c90a312d3cb3f75f95d",
    },
    transfer: {
      _id: {
        $oid: "65f27c90984769f352f1d863",
      },
      batch_transfer_index: 0,
      block_hash: "0x4bf7893647c4c27547706a111af91dabd04cda1faeca44960b02b00285407758",
      chain: "ethereum",
      log_index: 94,
      transaction: "0x6009c11fa80c113a8e857199250bdfb69ceadda51c639be4e3f1e197b2600e30",
      block_number: 19430871,
      collection_id: "98a3907dc90937a09c86f7c5a28a6545",
      contract_address: "0x9e8BC2765c8c06aE7eC358Dd916A35124Bb2bEF3",
      event_type: "transfer",
      from_address: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      nft_id: "ethereum.0x9e8bc2765c8c06ae7ec358dd916a35124bb2bef3.1225",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:26:47.000Z",
      },
      to_address: "0x305a8738F405AdEf6D85813d1218916c0652a698",
      token_id: "1225",
      transaction_initiator: "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
      transaction_to_address: "0x0000000000c2d145a2526bD8C716263bFeBe1A72",
      upsertedAt: {
        $date: "2024-03-14T04:26:56.060Z",
      },
      walletAddresses: [
        "0x257F75C99D45218BC1eB918c302E0c3E64ff2F9D",
        "0x305a8738F405AdEf6D85813d1218916c0652a698",
      ],
    },
    collection: {
      name: "WAGMI Games Founder's Packs",
      imageUrl:
        "https://lh3.googleusercontent.com/bouV9I8U2EUCHhDF9VD2S0zTyGFjF7BYZankpi1VdigYbwIxuR-oKnivXJqKyQrQ4SPh1aBknihO_8AAojHxt0iICYa2Ntlffw",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27cb5a312d3cb3f75f980",
    },
    transfer: {
      _id: {
        $oid: "65f27cb5984769f352f22b3a",
      },
      batch_transfer_index: 0,
      block_hash: "0x295a3f6076a99f3f9fc0f6bc22063227ef5a065ec61ef9cb65570fa51955b45d",
      chain: "ethereum",
      log_index: 138,
      transaction: "0x57e79d8d652426b58d7ea77068a3034a31e009be8e10044baf68ffd40cb65f3b",
      block_number: 19430874,
      collection_id: "bf620e7064fc2c3f29194d63cc9fb372",
      contract_address: "0xaCF63E56fd08970b43401492a02F6F38B6635C91",
      event_type: "transfer",
      from_address: "0xd801F7720e533810855739C4296f341Da941CD08",
      nft_id: "ethereum.0xacf63e56fd08970b43401492a02f6f38b6635c91.6646",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:27:23.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "6646",
      transaction_initiator: "0xd801F7720e533810855739C4296f341Da941CD08",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:27:33.114Z",
      },
      walletAddresses: [
        "0xd801F7720e533810855739C4296f341Da941CD08",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Kanpai Pandas",
      imageUrl:
        "https://lh3.googleusercontent.com/6-ZNYdg6ULc7mwNxky_5He948-L3x7r72b6iBVToasQjSZkzEMHQfB2S22tFH4CcBxmOvpeYVlLcu9yWdka2yBE3o-YZFvD-Efk",
    },
  },
  {
    isReceiver: true,
    filter: "Mint",
    _id: {
      $oid: "65f27d38b616dcc701c5ce2d",
    },
    transfer: {
      _id: {
        $oid: "65f27d38984769f352f2525b",
      },
      batch_transfer_index: 0,
      block_hash: "0x574d0d44ba103d7a7182e8c3df4b2fb034163b2f0af7ec566e65bd322ea02953",
      chain: "ethereum",
      log_index: 256,
      transaction: "0x99a6b2ff535244f0cebebb0d45ad62baea43deec1d26d3f928147f287f9b375b",
      block_number: 19430884,
      collection_id: "0cf477a0f94d223234b35c8383332a25",
      contract_address: "0x2b3c48Be4fB33B0724214aFF12b086B0214f8F15",
      event_type: "mint",
      from_address: null,
      nft_id: "ethereum.0x2b3c48be4fb33b0724214aff12b086b0214f8f15.159",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "primary_sale",
        marketplace_name: "Primary Sale",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "1000000000000000000",
        },
        total_price: {
          $numberLong: "1000000000000000000",
        },
        unit_price_usd_cents: 399445,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:29:23.000Z",
      },
      to_address: "0x0B4f222cB69554ee96bAE54abBd5583D40539266",
      token_id: "159",
      transaction_initiator: "0x0B4f222cB69554ee96bAE54abBd5583D40539266",
      transaction_to_address: "0x006A19248E1015479A6269BC24daA319a5E13a6e",
      upsertedAt: {
        $date: "2024-03-14T04:29:44.667Z",
      },
      walletAddresses: ["0x0B4f222cB69554ee96bAE54abBd5583D40539266"],
    },
    collection: {
      name: "CoinDesk Presents: Microcosms by Fahad Karim",
      imageUrl:
        "https://lh3.googleusercontent.com/KYnUHON5IgWbCuHDOerkpSb9_rldsrLqqe-vSx2nBVWIygLb6RS8Xb9n-TYql_TEJFVVXdN1yr9m7GPOKPrmPS71HlMb8IpsqRI",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27d5da312d3cb3f75f991",
    },
    transfer: {
      _id: {
        $oid: "65f27d5d984769f352f25d25",
      },
      batch_transfer_index: 0,
      block_hash: "0xe8427bb5759d27d647d8503cc58d6f0734fcab7f78ddab7ca47cc024344a85f1",
      chain: "ethereum",
      log_index: 335,
      transaction: "0x55bf1f37c31e73861000ef84ac5e019379342b9a9fd0e4c4a622f7ba1459efa1",
      block_number: 19430887,
      collection_id: "510139ec0d8cf5513d59a8d3afa1c061",
      contract_address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
      event_type: "transfer",
      from_address: "0xf9bCe92da0D4b8253077A6851a32bBD059E2A5A4",
      nft_id: "ethereum.0x8a90cab2b38dba80c64b7734e58ee1db38b8992e.9277",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:29:59.000Z",
      },
      to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      token_id: "9277",
      transaction_initiator: "0xf9bCe92da0D4b8253077A6851a32bBD059E2A5A4",
      transaction_to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      upsertedAt: {
        $date: "2024-03-14T04:30:21.003Z",
      },
      walletAddresses: [
        "0xf9bCe92da0D4b8253077A6851a32bBD059E2A5A4",
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      ],
    },
    collection: {
      name: "Doodles",
      imageUrl:
        "https://lh3.googleusercontent.com/2tqe0lUsBXzDDZGfv7KNjk2JrDeyMH4MdSDRoH8WF6APxWXQuSCVGiNkmJACLchXiwJZ8kgXu0X7RclZ3kOwLv-K29Hsuq3u9qA",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f27d97b616dcc701c5ce37",
    },
    transfer: {
      _id: {
        $oid: "65f27d97984769f352f26e48",
      },
      batch_transfer_index: 0,
      block_hash: "0x96b6633b283186ef1007474b3e7579f7297cc69e308e48e7dafd756d7a3fd1d9",
      chain: "ethereum",
      log_index: 110,
      transaction: "0xcf4bc8f15e51d3a1d6eb6991fdd3bd7b1a6a737e0c987388d693a1b3d003a500",
      block_number: 19430892,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.5388",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1378890000000000000",
        },
        total_price: {
          $numberLong: "1378890000000000000",
        },
        unit_price_usd_cents: 550791,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:30:59.000Z",
      },
      to_address: "0x717c4217Aa11BC3e5e94B045D6437f8c93Dc13Be",
      token_id: "5388",
      transaction_initiator: "0x717c4217Aa11BC3e5e94B045D6437f8c93Dc13Be",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:31:19.001Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x717c4217Aa11BC3e5e94B045D6437f8c93Dc13Be",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f27da0b616dcc701c5ce3e",
    },
    transfer: {
      _id: {
        $oid: "65f27da0984769f352f271e1",
      },
      batch_transfer_index: 0,
      block_hash: "0x2bb2af2213c5cf9d16021bf835a3aedbdfbf5435b6eedfb6ac864ed06d52138f",
      chain: "ethereum",
      log_index: 200,
      transaction: "0xb4446ae3b4aab79a178c68ea79c3b1649a266ed6e42ec60826ae54bc373cf72f",
      block_number: 19430893,
      collection_id: "510139ec0d8cf5513d59a8d3afa1c061",
      contract_address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
      event_type: "transfer",
      from_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      nft_id: "ethereum.0x8a90cab2b38dba80c64b7734e58ee1db38b8992e.362",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:31:11.000Z",
      },
      to_address: "0xf9bCe92da0D4b8253077A6851a32bBD059E2A5A4",
      token_id: "362",
      transaction_initiator: "0xf9bCe92da0D4b8253077A6851a32bBD059E2A5A4",
      transaction_to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      upsertedAt: {
        $date: "2024-03-14T04:31:28.470Z",
      },
      walletAddresses: [
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
        "0xf9bCe92da0D4b8253077A6851a32bBD059E2A5A4",
      ],
    },
    collection: {
      name: "Doodles",
      imageUrl:
        "https://lh3.googleusercontent.com/2tqe0lUsBXzDDZGfv7KNjk2JrDeyMH4MdSDRoH8WF6APxWXQuSCVGiNkmJACLchXiwJZ8kgXu0X7RclZ3kOwLv-K29Hsuq3u9qA",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f27e28a312d3cb3f75f99c",
    },
    transfer: {
      _id: {
        $oid: "65f27e28984769f352f292a8",
      },
      batch_transfer_index: 0,
      block_hash: "0x4d4da6c62eb1c8e88960dfb28b3096386d63c976d5f924e81ec830da4143c226",
      chain: "ethereum",
      log_index: 624,
      transaction: "0x98d9873f9d880ecf28e0f4c022a39776d5903ed08bd8888b80974b434bd0b6a5",
      block_number: 19430902,
      collection_id: "d488075bb6f40b72a372f2ff59bbf1af",
      contract_address: "0xe785E82358879F061BC3dcAC6f0444462D4b5330",
      event_type: "transfer",
      from_address: "0x49AD262C49C7aA708Cc2DF262eD53B64A17Dd5EE",
      nft_id: "ethereum.0xe785e82358879f061bc3dcac6f0444462d4b5330.1083",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:33:11.000Z",
      },
      to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      token_id: "1083",
      transaction_initiator: "0x1277f5266dEad289eB6Ab3f97a866f5854FEb33d",
      transaction_to_address: "0x49AD262C49C7aA708Cc2DF262eD53B64A17Dd5EE",
      upsertedAt: {
        $date: "2024-03-14T04:33:44.579Z",
      },
      walletAddresses: [
        "0x49AD262C49C7aA708Cc2DF262eD53B64A17Dd5EE",
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      ],
    },
    collection: {
      name: "World of Women",
      imageUrl:
        "https://lh3.googleusercontent.com/_HJOMZcoPpOOjRVnVX4eixlLMQA3FV-qcyvB2QI3HGLlJkY3qDQWVlAcTK5WfMy4cCbzAc5e6hJzw4sfMB-gE8HdcmFvW5UGBA",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f27f1da2477ab2e40b4fdf",
    },
    transfer: {
      _id: {
        $oid: "65f27f1d984769f352f30858",
      },
      batch_transfer_index: 0,
      block_hash: "0x0b538b5b57ada370e98cd97fe571ed89fea6b37abe79bb7ee148ff7859bd584c",
      chain: "ethereum",
      log_index: 372,
      transaction: "0x856127cbdd503150aca991def382e563d8d3803fe56f273c0ad7b5dbc9d4e561",
      block_number: 19430923,
      collection_id: "2c17420bea79ca1a5c440c2ef4ebadac",
      contract_address: "0x0BaECcD651cf4692A8790BCC4f606E79bF7A3B1c",
      event_type: "sale",
      from_address: "0x2Be2C0F632F69d3E5DAdB32b4DeC2689e1A77887",
      nft_id: "ethereum.0x0baeccd651cf4692a8790bcc4f606e79bf7a3b1c.20611",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "319000000000000000",
        },
        total_price: {
          $numberLong: "319000000000000000",
        },
        unit_price_usd_cents: 127423,
        fees: [],
      },
      timestamp: {
        $date: "2024-03-14T04:37:23.000Z",
      },
      to_address: "0x1f4598Dbe53a8231e06e264DDfB3Ea25051baEE6",
      token_id: "20611",
      transaction_initiator: "0x1f4598Dbe53a8231e06e264DDfB3Ea25051baEE6",
      transaction_to_address: "0xb2ecfE4E4D61f8790bbb9DE2D1259B9e2410CEA5",
      upsertedAt: {
        $date: "2024-03-14T04:37:49.230Z",
      },
      walletAddresses: [
        "0x2Be2C0F632F69d3E5DAdB32b4DeC2689e1A77887",
        "0x1f4598Dbe53a8231e06e264DDfB3Ea25051baEE6",
      ],
    },
    collection: {
      name: "Peplicator by Matt Furie",
      imageUrl:
        "https://lh3.googleusercontent.com/zRNVWiMtGvZjTeX4ynyjk2hRmgH1rwtpCrA3rfHNtMecvk5GOvMKu2YOSoyItpWXNojqbz9vHn3jpaZt-hQGIvdLCQDDLQRp85U",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f27f34a2477ab2e40b4fe6",
    },
    transfer: {
      _id: {
        $oid: "65f27f34984769f352f31360",
      },
      batch_transfer_index: 0,
      block_hash: "0xf43088a7a716d72d19d161fe39a33edce0b2003eeb045a84c26d43ff173db282",
      chain: "ethereum",
      log_index: 381,
      transaction: "0xfc1f636723ce2ec5881b957ff97a683f356660583f8e93705160ee7379b182e9",
      block_number: 19430926,
      collection_id: "7c710ec52b7f00c4383268c02c7f07c1",
      contract_address: "0x1A92f7381B9F03921564a437210bB9396471050C",
      event_type: "sale",
      from_address: "0x9b2F2f8c0544a33076d63AB9293397594a9bC138",
      nft_id: "ethereum.0x1a92f7381b9f03921564a437210bb9396471050c.1323",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "560000000000000000",
        },
        total_price: {
          $numberLong: "560000000000000000",
        },
        unit_price_usd_cents: 223689,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:37:59.000Z",
      },
      to_address: "0x5B8Eb0C77516cAA70b1cd4ED1757fAa4F8749aab",
      token_id: "1323",
      transaction_initiator: "0x5B8Eb0C77516cAA70b1cd4ED1757fAa4F8749aab",
      transaction_to_address: "0xb233e3602BB06AA2c2dB0982BBaf33c2b15184C9",
      upsertedAt: {
        $date: "2024-03-14T04:38:12.441Z",
      },
      walletAddresses: [
        "0x9b2F2f8c0544a33076d63AB9293397594a9bC138",
        "0x5B8Eb0C77516cAA70b1cd4ED1757fAa4F8749aab",
      ],
    },
    collection: {
      name: "Cool Cats",
      imageUrl:
        "https://lh3.googleusercontent.com/pCMTlkfx-5Oxf_Cl0vK5xMh8tshXbBBRB36KpLODcJSqtHmdR3hf4Gfx8RMQoDIQIPTv3xbtNtxA6w9wSeKwtmZVeJwjoFG63d0V",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f28062a312d3cb3f75f9b5",
    },
    transfer: {
      _id: {
        $oid: "65f28062984769f352f3dc3f",
      },
      batch_transfer_index: 0,
      block_hash: "0x46488317b853dfe8444aeb0e639c6046b2fde56acfbaf4bb41b8716209b5c543",
      chain: "ethereum",
      log_index: 324,
      transaction: "0xd8c2be564f3573a1848640983390aa4273b06ce5b429c0101a4fcc93a4438ab9",
      block_number: 19430951,
      collection_id: "3b5f8dab43d7da43d7a9f8c00885ce2c",
      contract_address: "0xD3D9ddd0CF0A5F0BFB8f7fcEAe075DF687eAEBaB",
      event_type: "transfer",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0xd3d9ddd0cf0a5f0bfb8f7fceae075df687eaebab.5122",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:42:59.000Z",
      },
      to_address: "0x620bBf02A572C4D3077813cc51F4eDd877243243",
      token_id: "5122",
      transaction_initiator: "0x620bBf02A572C4D3077813cc51F4eDd877243243",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:43:14.920Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x620bBf02A572C4D3077813cc51F4eDd877243243",
      ],
    },
    collection: {
      name: "Redacted Remilio Babies",
      imageUrl:
        "https://lh3.googleusercontent.com/jgMAVxpKaYhqDZt6UJJvviWb3dVxBBjyhWhFwzrSYw8e4RgyqlTI7zjEQ3jDsM1jV7qSEq8aZ88gZVd7Pcfu7YLO6-oTna5dzw",
    },
  },
  {
    isReceiver: true,
    filter: "Buy",
    _id: {
      $oid: "65f28066a312d3cb3f75f9ba",
    },
    transfer: {
      _id: {
        $oid: "65f28066984769f352f3de8d",
      },
      batch_transfer_index: 0,
      block_hash: "0x46488317b853dfe8444aeb0e639c6046b2fde56acfbaf4bb41b8716209b5c543",
      chain: "ethereum",
      log_index: 278,
      transaction: "0x82965ade17b5c3240d2808df08b8f851d082a08ff30842ce7191cc2a4461bc13",
      block_number: 19430951,
      collection_id: "bb5ff5dfae4816a2b0fa328f0649f6d6",
      contract_address: "0x4b15a9c28034dC83db40CD810001427d3BD7163D",
      event_type: "sale",
      from_address: "0xaa3eca80CA17C1B5B7382ce67b285CEAe730FBBf",
      nft_id: "ethereum.0x4b15a9c28034dc83db40cd810001427d3bd7163d.16383",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          alt_address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          decimals: 18,
          name: "Wrapped Ether",
          payment_token_id: "ethereum.0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
          symbol: "WETH",
        },
        unit_price: {
          $numberLong: "92300000000000000",
        },
        total_price: {
          $numberLong: "92300000000000000",
        },
        unit_price_usd_cents: 36684,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:42:59.000Z",
      },
      to_address: "0x36e2bb66942ae3A51f3d20e8c93Ce7D82187e214",
      token_id: "16383",
      transaction_initiator: "0xaa3eca80CA17C1B5B7382ce67b285CEAe730FBBf",
      transaction_to_address: "0x5ebc127fae83ed5bdd91fc6a5f5767E259dF5642",
      upsertedAt: {
        $date: "2024-03-14T04:43:18.609Z",
      },
      walletAddresses: [
        "0xaa3eca80CA17C1B5B7382ce67b285CEAe730FBBf",
        "0x36e2bb66942ae3A51f3d20e8c93Ce7D82187e214",
      ],
    },
    collection: {
      name: "HV-MTL",
      imageUrl:
        "https://lh3.googleusercontent.com/bih_9O4P-5aGr4oBPigshNXW1hZudef9y1P9k4DbswpUhJSB7HCbdQuCtdUFKxwFFVwzfZ2TgWmKcsurfmdiGCqzCt9AnSW_ow",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f28091b616dcc701c5ce54",
    },
    transfer: {
      _id: {
        $oid: "65f28091984769f352f3eee1",
      },
      batch_transfer_index: 0,
      block_hash: "0x211fee632c1bdfe1ede7a0e6e4b6b3c140d420611086a5ec9c09807286f94e6a",
      chain: "ethereum",
      log_index: 283,
      transaction: "0xde4ea88371354a740618b529a4ff2200df7fd0fd788f5b8a6af85d15647897dc",
      block_number: 19430955,
      collection_id: "c2848c940e984d247dbdeadd1fcded87",
      contract_address: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
      event_type: "transfer",
      from_address: "0x08f5F0126aF89B4fD5499E942891D904A027624B",
      nft_id: "ethereum.0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d.2918",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:43:47.000Z",
      },
      to_address: "0xaAa2DA255DF9Ee74C7075bCB6D81f97940908A5D",
      token_id: "2918",
      transaction_initiator: "0x6Ca8c7a28BA6b2a8A2184ce4D2fA60d158cb9fc8",
      transaction_to_address: "0x9Da9571389BA2316ad98f695210aD5fB0363cDEd",
      upsertedAt: {
        $date: "2024-03-14T04:44:01.141Z",
      },
      walletAddresses: [
        "0x08f5F0126aF89B4fD5499E942891D904A027624B",
        "0xaAa2DA255DF9Ee74C7075bCB6D81f97940908A5D",
      ],
    },
    collection: {
      name: "Bored Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/C_fjl1iM5iRwuk74N9DBrOmU-1-_lc_8x66BsWU8votTb3iwXiVJwmqJ2qd8BUI1DSDo_9KxcNcNJrdpnnxebLwpeJB7eiYSeI8",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f280b6b616dcc701c5ce5c",
    },
    transfer: {
      _id: {
        $oid: "65f280b6984769f352f3fd1f",
      },
      batch_transfer_index: 0,
      block_hash: "0xdaf1b9a23d2083f25718f494e3c204d8e8bc11409b407a54e1cefb3f6f81d354",
      chain: "ethereum",
      log_index: 179,
      transaction: "0x05fc24e8cd57841a024b234c22d817690bd89d903a9b5bff3e8dc93dc54105de",
      block_number: 19430957,
      collection_id: "a7fd6af5f212750d7ef7901db32f020d",
      contract_address: "0x5Af0D9827E0c53E4799BB226655A1de152A425a5",
      event_type: "transfer",
      from_address: "0x0F348c040A88DB2C086AC7188e728b7901623DA6",
      nft_id: "ethereum.0x5af0d9827e0c53e4799bb226655a1de152a425a5.8586",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:44:11.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "8586",
      transaction_initiator: "0x0F348c040A88DB2C086AC7188e728b7901623DA6",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:44:38.219Z",
      },
      walletAddresses: [
        "0x0F348c040A88DB2C086AC7188e728b7901623DA6",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Milady Maker",
      imageUrl:
        "https://lh3.googleusercontent.com/V7fVFUObJEFQjKKiDPnOmT_RuAsL63ZO3SBJgm2fXtt4ByOfmmTbPkNdgTTetyzxk8YmjT5CEQIjIBWAYmOEL6yMloxJBv_1FQ",
    },
  },
  {
    isReceiver: true,
    filter: "Mint",
    _id: {
      $oid: "65f280e4b616dcc701c5ce63",
    },
    transfer: {
      _id: {
        $oid: "65f280e4984769f352f40d90",
      },
      batch_transfer_index: 0,
      block_hash: "0x0b9d1141b3ae62348aea3abbbaa91a65026452df24c6bf8f362cfd1e69a1a02f",
      chain: "ethereum",
      log_index: 168,
      transaction: "0x718dc983961937bae5d9f53d7f4705f6e18162f3a913c1edd7dbc18d7ccb6f8d",
      block_number: 19430962,
      collection_id: "849ae53077f661aa445b59c7c1ac8c9a",
      contract_address: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
      event_type: "mint",
      from_address: null,
      nft_id: "ethereum.0xc36442b4a4522e871399cd717abdd847ab11fe88.692041",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:45:11.000Z",
      },
      to_address: "0x1336346bAd8cB1eeeE4E2e95d2a71B2153244D18",
      token_id: "692041",
      transaction_initiator: "0x1336346bAd8cB1eeeE4E2e95d2a71B2153244D18",
      transaction_to_address: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
      upsertedAt: {
        $date: "2024-03-14T04:45:24.624Z",
      },
      walletAddresses: ["0x1336346bAd8cB1eeeE4E2e95d2a71B2153244D18"],
    },
    collection: {
      name: "Uniswap V3 Positions",
      imageUrl:
        "https://lh3.googleusercontent.com/glk1YXFNgS81OOJxYK3LWo60eKutb3SHxBBnsSGap4KkOHO2Rh-p5m9T80MWNdebAUQjp9D0Y7NAGBZVRfElfD_DZRJQ_PffDARh",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f2817fb616dcc701c5ce69",
    },
    transfer: {
      _id: {
        $oid: "65f2817f984769f352f4a97c",
      },
      batch_transfer_index: 0,
      block_hash: "0xbac5aae5dd165075b1a2679629ae6410da9e0fc99c8247afaa4e696c4dc4d392",
      chain: "ethereum",
      log_index: 320,
      transaction: "0x0bf9309f940d15604b7061154873d97bea0a4c8db863eb205463ab57e0d2a680",
      block_number: 19430975,
      collection_id: "3b5f8dab43d7da43d7a9f8c00885ce2c",
      contract_address: "0xD3D9ddd0CF0A5F0BFB8f7fcEAe075DF687eAEBaB",
      event_type: "transfer",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0xd3d9ddd0cf0a5f0bfb8f7fceae075df687eaebab.9288",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:47:47.000Z",
      },
      to_address: "0x5ba89cAd1B7925083FdC91F8aFc5dff954df803F",
      token_id: "9288",
      transaction_initiator: "0x5ba89cAd1B7925083FdC91F8aFc5dff954df803F",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:47:59.057Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x5ba89cAd1B7925083FdC91F8aFc5dff954df803F",
      ],
    },
    collection: {
      name: "Redacted Remilio Babies",
      imageUrl:
        "https://lh3.googleusercontent.com/jgMAVxpKaYhqDZt6UJJvviWb3dVxBBjyhWhFwzrSYw8e4RgyqlTI7zjEQ3jDsM1jV7qSEq8aZ88gZVd7Pcfu7YLO6-oTna5dzw",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f28186a312d3cb3f75f9c6",
    },
    transfer: {
      _id: {
        $oid: "65f28186984769f352f4ac43",
      },
      batch_transfer_index: 0,
      block_hash: "0xa8745c04a7f25895fae305e0e9f7661e3e3361076b2ef915caf738c537b8f7f8",
      chain: "ethereum",
      log_index: 31,
      transaction: "0x50ef3c845d0ed914bf0c20e505a6da1d349d33215e6f508e2e74d64e5fd99248",
      block_number: 19430976,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.5625",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "2250000000000000000",
        },
        total_price: {
          $numberLong: "2250000000000000000",
        },
        unit_price_usd_cents: 894245,
        fees: [],
      },
      timestamp: {
        $date: "2024-03-14T04:47:59.000Z",
      },
      to_address: "0xfF0b9681ef40Fd0992Ef52fD5bd94E0Fa21c0359",
      token_id: "5625",
      transaction_initiator: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:48:06.408Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xfF0b9681ef40Fd0992Ef52fD5bd94E0Fa21c0359",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f281b3a312d3cb3f75f9d5",
    },
    transfer: {
      _id: {
        $oid: "65f281b3984769f352f4c158",
      },
      batch_transfer_index: 0,
      block_hash: "0xa8f16bc3ef9de0ee5121d6fc1846a1a069bcd8ec878eee1a8b8902361b9f3449",
      chain: "ethereum",
      log_index: 416,
      transaction: "0xcdd97842fd5e689e2a39e42be5bb559b89bb7f2094284b567bdd920ac0ce3951",
      block_number: 19430979,
      collection_id: "3b5f8dab43d7da43d7a9f8c00885ce2c",
      contract_address: "0xD3D9ddd0CF0A5F0BFB8f7fcEAe075DF687eAEBaB",
      event_type: "transfer",
      from_address: "0x5ba89cAd1B7925083FdC91F8aFc5dff954df803F",
      nft_id: "ethereum.0xd3d9ddd0cf0a5f0bfb8f7fceae075df687eaebab.9288",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:48:35.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "9288",
      transaction_initiator: "0x5ba89cAd1B7925083FdC91F8aFc5dff954df803F",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:48:51.790Z",
      },
      walletAddresses: [
        "0x5ba89cAd1B7925083FdC91F8aFc5dff954df803F",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Redacted Remilio Babies",
      imageUrl:
        "https://lh3.googleusercontent.com/jgMAVxpKaYhqDZt6UJJvviWb3dVxBBjyhWhFwzrSYw8e4RgyqlTI7zjEQ3jDsM1jV7qSEq8aZ88gZVd7Pcfu7YLO6-oTna5dzw",
    },
  },
  {
    isReceiver: false,
    filter: "Transfer from",
    _id: {
      $oid: "65f281b7a2477ab2e40b4ff4",
    },
    transfer: {
      _id: {
        $oid: "65f281b6984769f352f4c2ef",
      },
      batch_transfer_index: 0,
      block_hash: "0xa8f16bc3ef9de0ee5121d6fc1846a1a069bcd8ec878eee1a8b8902361b9f3449",
      chain: "ethereum",
      log_index: 378,
      transaction: "0x80cacfa1b3fba6a1524bab15acf6df9dc53535456e6da9e49fb9f40eb572b539",
      block_number: 19430979,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.8059",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:48:35.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "8059",
      transaction_initiator: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:48:54.989Z",
      },
      walletAddresses: [
        "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: true,
    filter: "Transfer to",
    _id: {
      $oid: "65f281b7a2477ab2e40b4ff6",
    },
    transfer: {
      _id: {
        $oid: "65f281b6984769f352f4c2ef",
      },
      batch_transfer_index: 0,
      block_hash: "0xa8f16bc3ef9de0ee5121d6fc1846a1a069bcd8ec878eee1a8b8902361b9f3449",
      chain: "ethereum",
      log_index: 378,
      transaction: "0x80cacfa1b3fba6a1524bab15acf6df9dc53535456e6da9e49fb9f40eb572b539",
      block_number: 19430979,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "transfer",
      from_address: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.8059",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:48:35.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "8059",
      transaction_initiator: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:48:54.989Z",
      },
      walletAddresses: [
        "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f281c3a2477ab2e40b4ffd",
    },
    transfer: {
      _id: {
        $oid: "65f281c3984769f352f4c92a",
      },
      batch_transfer_index: 0,
      block_hash: "0xb61449207b4130375730ec63b32b6fb43e43a175ae5792834b925914661d2ea5",
      chain: "ethereum",
      log_index: 109,
      transaction: "0xf75bccf66ff4d4946ad2a7f66e815fc50da327c1d91716f3161add8feaa50cdd",
      block_number: 19430980,
      collection_id: "2c17420bea79ca1a5c440c2ef4ebadac",
      contract_address: "0x0BaECcD651cf4692A8790BCC4f606E79bF7A3B1c",
      event_type: "sale",
      from_address: "0x05FA91c59008813c95eC49e7dbcDD8890cC99Fb7",
      nft_id: "ethereum.0x0baeccd651cf4692a8790bcc4f606e79bf7a3b1c.1911",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "191400000000000000",
        },
        total_price: {
          $numberLong: "191400000000000000",
        },
        unit_price_usd_cents: 76070,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:48:47.000Z",
      },
      to_address: "0x2cf5Ac2274eA9ed935Dc8973a24DbF434FE30089",
      token_id: "1911",
      transaction_initiator: "0x2cf5Ac2274eA9ed935Dc8973a24DbF434FE30089",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T04:49:07.438Z",
      },
      walletAddresses: [
        "0x05FA91c59008813c95eC49e7dbcDD8890cC99Fb7",
        "0x2cf5Ac2274eA9ed935Dc8973a24DbF434FE30089",
      ],
    },
    collection: {
      name: "Peplicator by Matt Furie",
      imageUrl:
        "https://lh3.googleusercontent.com/zRNVWiMtGvZjTeX4ynyjk2hRmgH1rwtpCrA3rfHNtMecvk5GOvMKu2YOSoyItpWXNojqbz9vHn3jpaZt-hQGIvdLCQDDLQRp85U",
    },
  },
  {
    isReceiver: false,
    filter: "Sell",
    _id: {
      $oid: "65f28213a312d3cb3f75f9de",
    },
    transfer: {
      _id: {
        $oid: "65f28213984769f352f4f127",
      },
      batch_transfer_index: 0,
      block_hash: "0xa63dfe2b24706eb63d1d8a9efe700b9a1926f6570f74b7d6f50aaa2d542c91b8",
      chain: "ethereum",
      log_index: 232,
      transaction: "0xeed9a3fd01e2064f4b1adc85be2e0c8a36bdadbf49b145986d689a920ca3d7ff",
      block_number: 19430987,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0xC7f32b05Cfb5E06F2Db7758E9852c443fB8B7231",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.16998",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "2300000000000000000",
        },
        total_price: {
          $numberLong: "2300000000000000000",
        },
        unit_price_usd_cents: 914117,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:50:11.000Z",
      },
      to_address: "0xC2BbDfe623021ee87601232B5C5A12C8E950d390",
      token_id: "16998",
      transaction_initiator: "0xC2BbDfe623021ee87601232B5C5A12C8E950d390",
      transaction_to_address: "0xb233e3602BB06AA2c2dB0982BBaf33c2b15184C9",
      upsertedAt: {
        $date: "2024-03-14T04:50:27.697Z",
      },
      walletAddresses: [
        "0xC7f32b05Cfb5E06F2Db7758E9852c443fB8B7231",
        "0xC2BbDfe623021ee87601232B5C5A12C8E950d390",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    isReceiver: false,
    _id: {
      $oid: "65f27501a2477ab2e40b4f63",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f27501984769f352ee886e",
      },
      batch_transfer_index: 4649538,
      block_hash: "00000000000000000001890ef1071a095e9cf5d5ebc7783effd44a194cd272bb",
      chain: "bitcoin",
      log_index: 3820002,
      transaction: "cac9d191e2cd287638e188268537aa3bd162a1e1f5d519dc1ce902514b358929",
      block_number: 834612,
      collection_id: "56f06febdbc15f51e655e18d21d6f6ac",
      contract_address: "916913cde5517447c1b01311ce8eb2937f49aa94e9cf70c575d50697c20c60e1i73",
      event_type: "sale",
      from_address: "bc1p54dt47a038658zpd43en7nlnpeglz7hm9suq5k4a05acne3jg5jqh50xm8",
      nft_id: "bitcoin.916913cde5517447c1b01311ce8eb2937f49aa94e9cf70c575d50697c20c60e1i73",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 8,
          name: "Bitcoin",
          payment_token_id: "bitcoin.native",
          symbol: "BTC",
        },
        unit_price: 140000,
        total_price: 140000,
        unit_price_usd_cents: 10225,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T03:51:22.000Z",
      },
      to_address: "bc1pgucy7hafv7faum3g5mrpjfj8kd5e8fv4rvdmgzj07j5e67xcn83sucr4ud",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:09:20.994Z",
      },
      walletAddresses: [
        "bc1p54dt47a038658zpd43en7nlnpeglz7hm9suq5k4a05acne3jg5jqh50xm8",
        "bc1pgucy7hafv7faum3g5mrpjfj8kd5e8fv4rvdmgzj07j5e67xcn83sucr4ud",
      ],
    },
    collection: {
      name: "RUNE KINGDOM",
      imageUrl:
        "https://lh3.googleusercontent.com/4_ePJs7QQ7XwAA-9FcafX6OT0fRGT5G1PY7-qwaZh0_awXIDZwynIvBlkj09z7ecKP0zXGBTGJtxeTkt3Yqn6dsoYvtDd77osA",
    },
  },
  {
    isReceiver: false,
    _id: {
      $oid: "65f27edeb616dcc701c5ce48",
    },
    filter: "Sell",
    transfer: {
      _id: {
        $oid: "65f27ede984769f352f2f733",
      },
      batch_transfer_index: 5541,
      block_hash: "00000000000000000002140ad2cd9254d0eeb7d0b60ea6ae5970d221e8139b04",
      chain: "bitcoin",
      log_index: 18490001,
      transaction: "f8dc1b137e20fad1d2dc94fc472bfad38efd215be3fb5b2bb7b6bf022853e1f8",
      block_number: 834614,
      collection_id: "043a8ba6ee41ccac8e8c34245416b1f0",
      contract_address: "c484c4fe6beb87482605f7eed8e4216d3d8088aed37b018f2683f834ad9f7ec4i0",
      event_type: "sale",
      from_address: "bc1p526tdtdzhvmxt6cxw7nvww56msj6vt5jdghumx8synr6ywdj36rsnr9gsx",
      nft_id: "bitcoin.c484c4fe6beb87482605f7eed8e4216d3d8088aed37b018f2683f834ad9f7ec4i0",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 8,
          name: "Bitcoin",
          payment_token_id: "bitcoin.native",
          symbol: "BTC",
        },
        unit_price: 17380000,
        total_price: 17380000,
        unit_price_usd_cents: 1273320,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:32:40.000Z",
      },
      to_address: "bc1phdf3ga0q2xgsad9h67cxwu2zdvdfz4dd37ta4x46trzuyj8qxhps4r85vr",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:36:46.131Z",
      },
      walletAddresses: [
        "bc1p526tdtdzhvmxt6cxw7nvww56msj6vt5jdghumx8synr6ywdj36rsnr9gsx",
        "bc1phdf3ga0q2xgsad9h67cxwu2zdvdfz4dd37ta4x46trzuyj8qxhps4r85vr",
      ],
    },
    collection: {
      name: "Bitcoin Puppets",
      imageUrl:
        "https://lh3.googleusercontent.com/YvVM53xL6tHIfDK_mv7KBpp_VZFqf6Kr7rH5MFj8vTSygDdhkTNn72NfCxDWO5I9w9dA-ON_4IE_qfMFEV67cCDGb0me8YUKb2-w",
    },
  },
  {
    isReceiver: false,
    _id: {
      $oid: "65f27f33a312d3cb3f75f9a2",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f27f33984769f352f312e0",
      },
      batch_transfer_index: 32296,
      block_hash: "00000000000000000002140ad2cd9254d0eeb7d0b60ea6ae5970d221e8139b04",
      chain: "bitcoin",
      log_index: 20160001,
      transaction: "d468b62db2dd52e8622c68a56a3a6c330640999933463614f4b992917dfb425b",
      block_number: 834614,
      collection_id: "56f06febdbc15f51e655e18d21d6f6ac",
      contract_address: "dc3d4d69be2acfc8702d8b839cccab8de7e1f749932166078d6e2073a3d5605ci238",
      event_type: "sale",
      from_address: "bc1p6qn78gqr5lpaxe6qulwv2p7rhsrdvqjhx68mltvwghqv74qha2ms8ztp8x",
      nft_id: "bitcoin.dc3d4d69be2acfc8702d8b839cccab8de7e1f749932166078d6e2073a3d5605ci238",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 8,
          name: "Bitcoin",
          payment_token_id: "bitcoin.native",
          symbol: "BTC",
        },
        unit_price: 150000,
        total_price: 150000,
        unit_price_usd_cents: 10990,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:32:40.000Z",
      },
      to_address: "bc1pwnt8us5ehtgusu9dnlza83gd2l98hxsw9ttua5htyy7cmfta35hstshm0f",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:39:29.387Z",
      },
      walletAddresses: [
        "bc1p6qn78gqr5lpaxe6qulwv2p7rhsrdvqjhx68mltvwghqv74qha2ms8ztp8x",
        "bc1pwnt8us5ehtgusu9dnlza83gd2l98hxsw9ttua5htyy7cmfta35hstshm0f",
      ],
    },
    collection: {
      name: "RUNE KINGDOM",
      imageUrl:
        "https://lh3.googleusercontent.com/4_ePJs7QQ7XwAA-9FcafX6OT0fRGT5G1PY7-qwaZh0_awXIDZwynIvBlkj09z7ecKP0zXGBTGJtxeTkt3Yqn6dsoYvtDd77osA",
    },
  },
  {
    _id: {
      $oid: "65f27f64a312d3cb3f75f9a4",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f27f64984769f352f32b50",
      },
      batch_transfer_index: 32780,
      block_hash: "00000000000000000002140ad2cd9254d0eeb7d0b60ea6ae5970d221e8139b04",
      chain: "bitcoin",
      log_index: 14160000,
      transaction: "2c77dbb8f999c3495f611accf7dd497774295c8b725a62d274318c0d87f28bae",
      block_number: 834614,
      collection_id: "9920e0bc455a3a050a6f93e88114ecfd",
      contract_address: "c9a1aa1aa645e5b490a6a8fa3a4025fc76851de6f8a72018a2140c60186622d8i0",
      event_type: "transfer",
      from_address: "bc1pcuq3s4z8phvrw5psd2s52qknf8mu3r839ffnms5aky7lelt62zeskk2y65",
      nft_id: "bitcoin.c9a1aa1aa645e5b490a6a8fa3a4025fc76851de6f8a72018a2140c60186622d8i0",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:32:40.000Z",
      },
      to_address: "bc1pmalqz734jxjjterzyh0ygfe2ljd9cn7sx8zqs6esg3207n77fzrqc98t9q",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:39:00.269Z",
      },
      walletAddresses: [
        "bc1pcuq3s4z8phvrw5psd2s52qknf8mu3r839ffnms5aky7lelt62zeskk2y65",
        "bc1pmalqz734jxjjterzyh0ygfe2ljd9cn7sx8zqs6esg3207n77fzrqc98t9q",
      ],
    },
    collection: {
      name: "NodeMonkes",
      imageUrl:
        "https://lh3.googleusercontent.com/oB5YJTJolhuZbhAuVZZDU0PQ6753XauVvogWqL0K9JuOeN4DHg-UsG-kUjJUYu7NNyOFnTpfpQkYtaGqffsEkFhUgD4HgnNoeO0",
    },
  },
  {
    isReceiver: false,
    _id: {
      $oid: "65f27f79b616dcc701c5ce4f",
    },
    filter: "Sell",
    transfer: {
      _id: {
        $oid: "65f27f79984769f352f348f0",
      },
      batch_transfer_index: 8416,
      block_hash: "00000000000000000002140ad2cd9254d0eeb7d0b60ea6ae5970d221e8139b04",
      chain: "bitcoin",
      log_index: 18480001,
      transaction: "f680630f63eb07acbb4d4f8e46348af0cc9e2715b9680701a142c8062dae94ba",
      block_number: 834614,
      collection_id: "dccbaf5755db1c6bb86bf3b472efe3c2",
      contract_address: "5bcae59edd671f6859d848a39a8ada7fd6c48c152731c26a7d87808b2cf503b5i127",
      event_type: "sale",
      from_address: "bc1pq5j5y3makm0vr4nlwgwqxy5e9da3e2tpfqw6rk05f88uc4fdhqvqfnpwas",
      nft_id: "bitcoin.5bcae59edd671f6859d848a39a8ada7fd6c48c152731c26a7d87808b2cf503b5i127",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 8,
          name: "Bitcoin",
          payment_token_id: "bitcoin.native",
          symbol: "BTC",
        },
        unit_price: 1055000,
        total_price: 1055000,
        unit_price_usd_cents: 77293,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:32:40.000Z",
      },
      to_address: "bc1pddtaql2klsjyd99enfvyufrjcs6gexty4adxrpvxjxvfc0pyel7qr24sz4",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:39:21.087Z",
      },
      walletAddresses: [
        "bc1pq5j5y3makm0vr4nlwgwqxy5e9da3e2tpfqw6rk05f88uc4fdhqvqfnpwas",
        "bc1pddtaql2klsjyd99enfvyufrjcs6gexty4adxrpvxjxvfc0pyel7qr24sz4",
      ],
    },
    collection: {
      name: "Apostles of Greed 🟠",
      imageUrl:
        "https://lh3.googleusercontent.com/x0ioglqMHgzZFjwHOLX7KHJxyYX0gAb5fCTLWQFdcnf6L_0t9Ty5_CTAUuVZK3Fr0RMMrksHiCKwZ-6vvqY6hKvqwgImvW7akQ",
    },
  },
  {
    isReceiver: false,
    _id: {
      $oid: "65f281ffb616dcc701c5ce70",
    },
    filter: "Sell",
    transfer: {
      _id: {
        $oid: "65f281ff984769f352f4e59d",
      },
      batch_transfer_index: 34587,
      block_hash: "00000000000000000001bef5be535b99a5a0c68fadee11fefeff11da879a8113",
      chain: "bitcoin",
      log_index: 16500001,
      transaction: "f9fdcaf7cfb131e5a4f53fbbc04b167e5d7701f6e8dac22960774a5b61f4dae3",
      block_number: 834615,
      collection_id: "20687f8e6f646f93ee7ba89e2f6f61c9",
      contract_address: "7b85a4c9c333f47e57b4a5c07097ebf224d0631f4b444d1e18d67ad38facef3fi0",
      event_type: "sale",
      from_address: "bc1pkcteshl8xpuh0d0dc3nudpwftfa6k02c2yf42mdyrtgem7q85q4sk4g3k6",
      nft_id: "bitcoin.7b85a4c9c333f47e57b4a5c07097ebf224d0631f4b444d1e18d67ad38facef3fi0",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "magiceden",
        marketplace_name: "Magic Eden",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 8,
          name: "Bitcoin",
          payment_token_id: "bitcoin.native",
          symbol: "BTC",
        },
        unit_price: 965000,
        total_price: 965000,
        unit_price_usd_cents: 70699,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:46:58.000Z",
      },
      to_address: "bc1pza8evk7cer9f9t6x003udvsscz9rhhtn29n2vcrcdp038f3dej9saq9u5z",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:50:07.051Z",
      },
      walletAddresses: [
        "bc1pkcteshl8xpuh0d0dc3nudpwftfa6k02c2yf42mdyrtgem7q85q4sk4g3k6",
        "bc1pza8evk7cer9f9t6x003udvsscz9rhhtn29n2vcrcdp038f3dej9saq9u5z",
      ],
    },
    collection: {
      name: "Honey Badgers",
      imageUrl:
        "https://lh3.googleusercontent.com/UEB1apJwCz1XPnHKni02JGE8FX3XKWe2Zg-5d2XcxMyoAQdXmD0UKFGh1HExxSgVXG4ncZbZTH5ZTWzmk1-5Fq_zfHz27rm5Lks",
    },
  },
  {
    _id: {
      $oid: "65f28215b616dcc701c5ce72",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f28215984769f352f4f1bd",
      },
      batch_transfer_index: 42206,
      block_hash: "00000000000000000001bef5be535b99a5a0c68fadee11fefeff11da879a8113",
      chain: "bitcoin",
      log_index: 10010002,
      transaction: "961fc68689e2a5f87259f1fdc6a583f7cb20ea3da63056277489227e9ab8525a",
      block_number: 834615,
      collection_id: "043a8ba6ee41ccac8e8c34245416b1f0",
      contract_address: "e2c8d3349a6dff80bdb26c2349c034255fed1f8ca7f26e83817057b28d7ddfc3i0",
      event_type: "transfer",
      from_address: "bc1ppdgcldesrur0j6750fqffs0m2qx8a2x2d80cza45z98ry6a6xjeqwat37w",
      nft_id: "bitcoin.e2c8d3349a6dff80bdb26c2349c034255fed1f8ca7f26e83817057b28d7ddfc3i0",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:46:58.000Z",
      },
      to_address: "bc1pjaprwcfv78a80agr0jdvm7hynwxxk7na7ut5atr7mwt9vqtzuxzq7vc8xg",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:50:29.192Z",
      },
      walletAddresses: [
        "bc1ppdgcldesrur0j6750fqffs0m2qx8a2x2d80cza45z98ry6a6xjeqwat37w",
        "bc1pjaprwcfv78a80agr0jdvm7hynwxxk7na7ut5atr7mwt9vqtzuxzq7vc8xg",
      ],
    },
    collection: {
      name: "Bitcoin Puppets",
      imageUrl:
        "https://lh3.googleusercontent.com/YvVM53xL6tHIfDK_mv7KBpp_VZFqf6Kr7rH5MFj8vTSygDdhkTNn72NfCxDWO5I9w9dA-ON_4IE_qfMFEV67cCDGb0me8YUKb2-w",
    },
  },
  {
    _id: {
      $oid: "65f28216a2477ab2e40b5000",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f28216984769f352f4f291",
      },
      batch_transfer_index: 56036,
      block_hash: "00000000000000000001bef5be535b99a5a0c68fadee11fefeff11da879a8113",
      chain: "bitcoin",
      log_index: 10010001,
      transaction: "961fc68689e2a5f87259f1fdc6a583f7cb20ea3da63056277489227e9ab8525a",
      block_number: 834615,
      collection_id: "69915994cf7f974786397ab4bee42619",
      contract_address: "9d1c254cdc8fc56adbb88477943124dfb39122d1473ca42138053d2948c5b63bi0",
      event_type: "transfer",
      from_address: "bc1ppdgcldesrur0j6750fqffs0m2qx8a2x2d80cza45z98ry6a6xjeqwat37w",
      nft_id: "bitcoin.9d1c254cdc8fc56adbb88477943124dfb39122d1473ca42138053d2948c5b63bi0",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:46:58.000Z",
      },
      to_address: "bc1pjaprwcfv78a80agr0jdvm7hynwxxk7na7ut5atr7mwt9vqtzuxzq7vc8xg",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:50:30.506Z",
      },
      walletAddresses: [
        "bc1ppdgcldesrur0j6750fqffs0m2qx8a2x2d80cza45z98ry6a6xjeqwat37w",
        "bc1pjaprwcfv78a80agr0jdvm7hynwxxk7na7ut5atr7mwt9vqtzuxzq7vc8xg",
      ],
    },
    collection: {
      name: "O.P.I.U.M.",
      imageUrl:
        "https://lh3.googleusercontent.com/Jai0UGwpfgWRVwXeovveYlkwYwaXdyI9daVxK2y9dq0DqdIibm-WSlfFcx0rSMp7tuYu5XJJ0Rmun9zAHeMODkT3Lt0dHcLMW5g",
    },
  },
  {
    _id: {
      $oid: "65f28216a2477ab2e40b5001",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f28216984769f352f4f292",
      },
      batch_transfer_index: 57563,
      block_hash: "00000000000000000001bef5be535b99a5a0c68fadee11fefeff11da879a8113",
      chain: "bitcoin",
      log_index: 10010000,
      transaction: "961fc68689e2a5f87259f1fdc6a583f7cb20ea3da63056277489227e9ab8525a",
      block_number: 834615,
      collection_id: "92b2a813d6627c93ab2796f55f255463",
      contract_address: "640c8985cb213dffdb3bb7876569c2a58cc87073fa0f66dfae98e73478efb38di0",
      event_type: "transfer",
      from_address: "bc1ppdgcldesrur0j6750fqffs0m2qx8a2x2d80cza45z98ry6a6xjeqwat37w",
      nft_id: "bitcoin.640c8985cb213dffdb3bb7876569c2a58cc87073fa0f66dfae98e73478efb38di0",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:46:58.000Z",
      },
      to_address: "bc1pjaprwcfv78a80agr0jdvm7hynwxxk7na7ut5atr7mwt9vqtzuxzq7vc8xg",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:50:30.506Z",
      },
      walletAddresses: [
        "bc1ppdgcldesrur0j6750fqffs0m2qx8a2x2d80cza45z98ry6a6xjeqwat37w",
        "bc1pjaprwcfv78a80agr0jdvm7hynwxxk7na7ut5atr7mwt9vqtzuxzq7vc8xg",
      ],
    },
    collection: {
      name: "Bitcoin Bandits",
      imageUrl:
        "https://lh3.googleusercontent.com/bB73rYe0UEDxu7i21FweIFRleqnqxkFaAy7Gp2ESV4TdhBZQTvy0nCDZckf2qOlL1uDK1pGex_34urY2MoNZzEBtspkusreqmA",
    },
  },
  {
    _id: {
      $oid: "65f28236b616dcc701c5ce74",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f28236984769f352f5042d",
      },
      batch_transfer_index: 28373,
      block_hash: "00000000000000000002a563e8135ee919e3c97ff12809aef16dbba87ceeb964",
      chain: "bitcoin",
      log_index: 9010000,
      transaction: "053b4df463f6bda901c36995416677040a116d829abe081ba7cc5ccf99fd3e05",
      block_number: 834616,
      collection_id: "043a8ba6ee41ccac8e8c34245416b1f0",
      contract_address: "e7f1635383adb9714973b4fc650e81e928c2e5931b499f85c32adb2b3c798796i0",
      event_type: "transfer",
      from_address: "bc1p35r95lnlxwpu2j2j9l2dukur2cdx2n2h6q3906hxvy5pjk4s8e8s328rgs",
      nft_id: "bitcoin.e7f1635383adb9714973b4fc650e81e928c2e5931b499f85c32adb2b3c798796i0",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:49:37.000Z",
      },
      to_address: "bc1pcxnfn0k2tjjfelykcgpapc7qtc7hgkx58n8ve9znh9utnj82qgss8d3xta",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:51:02.401Z",
      },
      walletAddresses: [
        "bc1p35r95lnlxwpu2j2j9l2dukur2cdx2n2h6q3906hxvy5pjk4s8e8s328rgs",
        "bc1pcxnfn0k2tjjfelykcgpapc7qtc7hgkx58n8ve9znh9utnj82qgss8d3xta",
      ],
    },
    collection: {
      name: "Bitcoin Puppets",
      imageUrl:
        "https://lh3.googleusercontent.com/YvVM53xL6tHIfDK_mv7KBpp_VZFqf6Kr7rH5MFj8vTSygDdhkTNn72NfCxDWO5I9w9dA-ON_4IE_qfMFEV67cCDGb0me8YUKb2-w",
    },
  },
  {
    _id: {
      $oid: "65f28256b616dcc701c5ce77",
    },
    filter: "Transfer from",
    transfer: {
      _id: {
        $oid: "65f28255984769f352f5194b",
      },
      batch_transfer_index: 62963,
      block_hash: "00000000000000000002a563e8135ee919e3c97ff12809aef16dbba87ceeb964",
      chain: "bitcoin",
      log_index: 10770000,
      transaction: "437a1e50cceccc6018867a64d3299177ae55ef7476f7772e3d351f42582bfcc6",
      block_number: 834616,
      collection_id: "dccbaf5755db1c6bb86bf3b472efe3c2",
      contract_address: "a0033c180416a9690d466088725c65523884c28eb056dd835299571909e8f507i133",
      event_type: "transfer",
      from_address: "bc1pcv27p6pzwe43lg45d2v54kmjlh3gu7w2zjalh8recv55hsexqa4q8rndz7",
      nft_id: "bitcoin.a0033c180416a9690d466088725c65523884c28eb056dd835299571909e8f507i133",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:49:37.000Z",
      },
      to_address: "bc1pt0dfz4ns3dkd6mtu72sa5atqfhlr5wsv34vhnr4y63sw4smnctpqx4xed7",
      token_id: null,
      transaction_initiator: null,
      transaction_to_address: null,
      upsertedAt: {
        $date: "2024-03-14T04:51:33.986Z",
      },
      walletAddresses: [
        "bc1pcv27p6pzwe43lg45d2v54kmjlh3gu7w2zjalh8recv55hsexqa4q8rndz7",
        "bc1pt0dfz4ns3dkd6mtu72sa5atqfhlr5wsv34vhnr4y63sw4smnctpqx4xed7",
      ],
    },
    collection: {
      name: "Apostles of Greed 🟠",
      imageUrl:
        "https://lh3.googleusercontent.com/x0ioglqMHgzZFjwHOLX7KHJxyYX0gAb5fCTLWQFdcnf6L_0t9Ty5_CTAUuVZK3Fr0RMMrksHiCKwZ-6vvqY6hKvqwgImvW7akQ",
    },
  },
  {
    filter: "Mint",
    isReceiver: true,
    _id: {
      $oid: "65f274fea2477ab2e40b4f60",
    },
    transfer: {
      _id: {
        $oid: "65f274fe984769f352ee8584",
      },
      batch_transfer_index: 0,
      block_hash: "0x6c74cb2cf62130df2f6250086f0edc4c373db7daa2d1d89ac63c88c655828d65",
      chain: "ethereum",
      log_index: 474,
      transaction: "0x1361c10af798f37e06a3fcde2816ece27f9f6db2045a66bb6c7b874e5ac570b3",
      block_number: 19430710,
      collection_id: "dd27ec24781017fe4d214ec4193ea145",
      contract_address: "0x3Bdca51226202Fc2a64211Aa35A8d95D61C6ca99",
      event_type: "mint",
      from_address: null,
      nft_id: "ethereum.0x3bdca51226202fc2a64211aa35a8d95d61c6ca99.28057",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:54:23.000Z",
      },
      to_address: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      token_id: "28057",
      transaction_initiator: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      transaction_to_address: "0x3Bdca51226202Fc2a64211Aa35A8d95D61C6ca99",
      upsertedAt: {
        $date: "2024-03-14T03:54:38.911Z",
      },
      walletAddresses: ["0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09"],
    },
    collection: {
      name: "Otherside Mara",
      imageUrl:
        "https://lh3.googleusercontent.com/m4vwedAcv-J0iamg0aUaq0nPuK8MoU2TpgZs_nET48dSGtktBwhyUlb4V1HQgu2sQLWAWPPu-MPJw6H_2tOomCsgqLxYwDDXRQ",
    },
  },
  {
    filter: "Burn",
    isReceiver: false,
    _id: {
      $oid: "65f274fea312d3cb3f75f89e",
    },
    transfer: {
      _id: {
        $oid: "65f274fe984769f352ee847d",
      },
      batch_transfer_index: 0,
      block_hash: "0x6c74cb2cf62130df2f6250086f0edc4c373db7daa2d1d89ac63c88c655828d65",
      chain: "ethereum",
      log_index: 475,
      transaction: "0x1361c10af798f37e06a3fcde2816ece27f9f6db2045a66bb6c7b874e5ac570b3",
      block_number: 19430710,
      collection_id: "faa8daac814f6e2d73f48d90f5a48b62",
      contract_address: "0x5b1085136a811e55b2Bb2CA1eA456bA82126A376",
      event_type: "burn",
      from_address: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      nft_id: "ethereum.0x5b1085136a811e55b2bb2ca1ea456ba82126a376.28057",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:54:23.000Z",
      },
      to_address: null,
      token_id: "28057",
      transaction_initiator: "0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09",
      transaction_to_address: "0x3Bdca51226202Fc2a64211Aa35A8d95D61C6ca99",
      upsertedAt: {
        $date: "2024-03-14T03:54:38.196Z",
      },
      walletAddresses: ["0xe92F4F0B731763281Cb6fcb672A6B6ED98812E09"],
    },
    collection: {
      name: "Otherside Vessels",
      imageUrl:
        "https://lh3.googleusercontent.com/5BFUXaf7S0dDdQOFdQQkPkK6unx5iDWtE7gIo4LpAka5IbaqgkRk6Ia58LMAonSycLgvv5om0MrFDN4VYNbri6LDHCXa5xjpNw",
    },
  },
  {
    filter: "Sell",
    isReceiver: false,
    _id: {
      $oid: "65f275f5a312d3cb3f75f8aa",
    },
    transfer: {
      _id: {
        $oid: "65f275f5984769f352eee855",
      },
      batch_transfer_index: 0,
      block_hash: "0x9c4b066d8fe86b7c34d9cd076830b8495686d034580500a0e5dc61c012ff163b",
      chain: "ethereum",
      log_index: 36,
      transaction: "0xa26eea60dcf3489a6f236bb764487a1050057311d6b07b41d02d8c623156b1f9",
      block_number: 19430731,
      collection_id: "7c710ec52b7f00c4383268c02c7f07c1",
      contract_address: "0x1A92f7381B9F03921564a437210bB9396471050C",
      event_type: "sale",
      from_address: "0xc2079e5B077eae969dBEcA3747BB855Ae9DF8f3F",
      nft_id: "ethereum.0x1a92f7381b9f03921564a437210bb9396471050c.623",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "500000000000000000",
        },
        total_price: {
          $numberLong: "500000000000000000",
        },
        unit_price_usd_cents: 199340,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T03:58:35.000Z",
      },
      to_address: "0x0b4648Ea410f0a907107dd61b72b447DFefc0C10",
      token_id: "623",
      transaction_initiator: "0x0b4648Ea410f0a907107dd61b72b447DFefc0C10",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T03:58:45.788Z",
      },
      walletAddresses: [
        "0xc2079e5B077eae969dBEcA3747BB855Ae9DF8f3F",
        "0x0b4648Ea410f0a907107dd61b72b447DFefc0C10",
      ],
    },
    collection: {
      name: "Cool Cats",
      imageUrl:
        "https://lh3.googleusercontent.com/pCMTlkfx-5Oxf_Cl0vK5xMh8tshXbBBRB36KpLODcJSqtHmdR3hf4Gfx8RMQoDIQIPTv3xbtNtxA6w9wSeKwtmZVeJwjoFG63d0V",
    },
  },
  {
    filter: "Transfer to",
    isReceiver: true,
    _id: {
      $oid: "65f27635a312d3cb3f75f8bd",
    },
    transfer: {
      _id: {
        $oid: "65f27635984769f352ef015c",
      },
      batch_transfer_index: 0,
      block_hash: "0xdccdb0c720d29f6adb9c14a01e86666170a1adde63f8e2fe8a2feb045a382899",
      chain: "ethereum",
      log_index: 401,
      transaction: "0x6ec3e08e7e4f0268debce9b90818255aa558f387f0b2aa4cd4bfe72ef8c91aea",
      block_number: 19430736,
      collection_id: "2ccfd6cb165074e4881906dcc701f1d8",
      contract_address: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
      event_type: "transfer",
      from_address: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      nft_id: "ethereum.0xed5af388653567af2f388e6224dc7c4b3241c544.7208",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T03:59:35.000Z",
      },
      to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      token_id: "7208",
      transaction_initiator: "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T03:59:49.236Z",
      },
      walletAddresses: [
        "0xa69833B9fDa816f1bFC79517E7932E64708Df0dd",
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      ],
    },
    collection: {
      name: "Azuki",
      imageUrl:
        "https://lh3.googleusercontent.com/HTfDnsQfaqpkdPB0OITH5RSoAHKTUDYsB03S2jaClYMbnhhoTIB7u4Ln8QU01OvkJky1Tm1s6DsQtZMDqZcZ-zpy9lizOahrHA",
    },
  },
  {
    filter: "Sell",
    isReceiver: false,
    _id: {
      $oid: "65f276aaa2477ab2e40b4f77",
    },
    transfer: {
      _id: {
        $oid: "65f276aa984769f352ef234e",
      },
      batch_transfer_index: 0,
      block_hash: "0xf57634357bc209e66b71630eb97a031a7d3a7da8d283d2629cbbc8be8c5d55cb",
      chain: "ethereum",
      log_index: 62,
      transaction: "0x763e07f649b3f03951aaf3e8774eab973f52312667963d32b65319b365eb48f5",
      block_number: 19430746,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.11578",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "2295000000000000000",
        },
        total_price: {
          $numberLong: "2295000000000000000",
        },
        unit_price_usd_cents: 916726,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:01:35.000Z",
      },
      to_address: "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      token_id: "11578",
      transaction_initiator: "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:01:46.435Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xA82801Ddf9f5B974085e0450BeBDD95365F5C3ab",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    filter: "Sell",
    isReceiver: false,
    _id: {
      $oid: "65f27770a312d3cb3f75f8d0",
    },
    transfer: {
      _id: {
        $oid: "65f27770984769f352efa5fd",
      },
      batch_transfer_index: 0,
      block_hash: "0x6077d0c0d31f5032a7d2416c34860cbd0d68a6e9e8462da8434cd0ffd4d4f09b",
      chain: "ethereum",
      log_index: 143,
      transaction: "0x56b4fa821a5c4b8b920bf4bfc432929f9905a2230193ec65391caf13c6c6f1ea",
      block_number: 19430762,
      collection_id: "5022732d9bb67c26a61932d3cd9816d8",
      contract_address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x60e4d786628fea6478f785a6d7e704777c86a7c6.24084",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "2260000000000000000",
        },
        total_price: {
          $numberLong: "2260000000000000000",
        },
        unit_price_usd_cents: 902746,
        fees: [],
      },
      timestamp: {
        $date: "2024-03-14T04:04:47.000Z",
      },
      to_address: "0x1eCbC285F34390516FB69797949D9d4Ca3F86CFf",
      token_id: "24084",
      transaction_initiator: "0xfF0b9681ef40Fd0992Ef52fD5bd94E0Fa21c0359",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:05:04.529Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0x1eCbC285F34390516FB69797949D9d4Ca3F86CFf",
      ],
    },
    collection: {
      name: "Mutant Ape Yacht Club",
      imageUrl:
        "https://lh3.googleusercontent.com/z33Y-z7aURg8ZLwuukYoKmeC7zQW4suCNEljTK27tqdy632Yd3AHMuA5KZauhadoQJITR0YMsnnx517feAstoGc3kIuM14Jo2A",
    },
  },
  {
    filter: "Sell",
    isReceiver: false,
    _id: {
      $oid: "65f277b4b616dcc701c5cdc7",
    },
    transfer: {
      _id: {
        $oid: "65f277b4984769f352efbd7b",
      },
      batch_transfer_index: 0,
      block_hash: "0xb1c0cf9d1b5be9f30d91495717714268efd22b439c44a1b08079eaab7edfa9e9",
      chain: "ethereum",
      log_index: 259,
      transaction: "0x1db2ab540950321789068885f121adaaa8ef0c93ad41f8a2ce2686cae3bde7a4",
      block_number: 19430768,
      collection_id: "a862e29b34065fd5b8f606943d04d08a",
      contract_address: "0x63578cA1Be3aB8e75d88854485e8C23B41D87B16",
      event_type: "sale",
      from_address: "0x1f231bD2acc079f3A9c7aE3074479fe6B0FdB1F0",
      nft_id: "ethereum.0x63578ca1be3ab8e75d88854485e8c23b41d87b16.3897",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "opensea",
        marketplace_name: "OpenSea",
        is_bundle_sale: false,
        payment_token: {
          address: null,
          alt_address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
          name: "Ether",
          payment_token_id: "ethereum.native",
          symbol: "ETH",
        },
        unit_price: {
          $numberLong: "71800000000000000",
        },
        total_price: {
          $numberLong: "71800000000000000",
        },
        unit_price_usd_cents: 28680,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:05:59.000Z",
      },
      to_address: "0x9f0940E02D869a7b5B99C9d2a54F58A382d97824",
      token_id: "3897",
      transaction_initiator: "0x9f0940E02D869a7b5B99C9d2a54F58A382d97824",
      transaction_to_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
      upsertedAt: {
        $date: "2024-03-14T04:06:12.014Z",
      },
      walletAddresses: [
        "0x1f231bD2acc079f3A9c7aE3074479fe6B0FdB1F0",
        "0x9f0940E02D869a7b5B99C9d2a54F58A382d97824",
      ],
    },
    collection: {
      name: "Chiblings",
      imageUrl:
        "https://lh3.googleusercontent.com/xHdt_FgSVWoJZRuRFhNosw2PNt0HXlVmlyQPr5gDdEESsXUoGv9WerAgO-FZeJy2MnqnUgUGbkNAsv9jnjBhTvhJD01kNkCW8v4",
    },
  },
  {
    filter: "Transfer from",
    isReceiver: false,
    _id: {
      $oid: "65f277cba312d3cb3f75f8da",
    },
    transfer: {
      _id: {
        $oid: "65f277cb984769f352efc843",
      },
      batch_transfer_index: 0,
      block_hash: "0x56858f38d3b569441cb340e19f3df2e641ce119c1e41ef3728d56114a8421691",
      chain: "ethereum",
      log_index: 292,
      transaction: "0x767522ba83be416f4fd498fa91db1147f815fcacb84aaa4aa51590b256a7053f",
      block_number: 19430770,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "transfer",
      from_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.1491",
      quantity: 1,
      quantity_string: "1",
      sale_details: null,
      timestamp: {
        $date: "2024-03-14T04:06:23.000Z",
      },
      to_address: "0xcF749c020f1d187c199fb0533d59f0DC3B40f798",
      token_id: "1491",
      transaction_initiator: "0xcF749c020f1d187c199fb0533d59f0DC3B40f798",
      transaction_to_address: "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
      upsertedAt: {
        $date: "2024-03-14T04:06:35.935Z",
      },
      walletAddresses: [
        "0x3eb879cc9a0Ef4C6f1d870A40ae187768c278Da2",
        "0xcF749c020f1d187c199fb0533d59f0DC3B40f798",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
  {
    filter: "Sell",
    isReceiver: false,
    _id: {
      $oid: "65f277d5b616dcc701c5cdd2",
    },
    transfer: {
      _id: {
        $oid: "65f277d5984769f352efcc37",
      },
      batch_transfer_index: 0,
      block_hash: "0x623022ad80682ae3ed54e9f6ba08aaa9dc569af1a523bb6004620cf495d3ca79",
      chain: "ethereum",
      log_index: 174,
      transaction: "0x1387130ae75f64b3a0a8c9cfb248eff2db617ece73a3f1d19ea50e2d8bbbab9a",
      block_number: 19430771,
      collection_id: "b43dc80ada56435599e975f6f99300d8",
      contract_address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
      event_type: "sale",
      from_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      nft_id: "ethereum.0x8821bee2ba0df28761afff119d66390d594cd280.4994",
      quantity: 1,
      quantity_string: "1",
      sale_details: {
        marketplace_id: "blur",
        marketplace_name: "Blur",
        is_bundle_sale: false,
        payment_token: {
          address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          alt_address: "0x0000000000A39bb272e79075ade125fd351887Ac",
          decimals: 18,
          name: "Blur Pool",
          payment_token_id: "ethereum.0x0000000000a39bb272e79075ade125fd351887ac",
          symbol: null,
        },
        unit_price: {
          $numberLong: "1332700000000000000",
        },
        total_price: {
          $numberLong: "1332700000000000000",
        },
        unit_price_usd_cents: 532340,
        fees: null,
      },
      timestamp: {
        $date: "2024-03-14T04:06:35.000Z",
      },
      to_address: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      token_id: "4994",
      transaction_initiator: "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      transaction_to_address: "0x29469395eAf6f95920E59F858042f0e28D98a20B",
      upsertedAt: {
        $date: "2024-03-14T04:06:45.845Z",
      },
      walletAddresses: [
        "0x29469395eAf6f95920E59F858042f0e28D98a20B",
        "0xe8678F8DfcB5b469ED7cd434325EB5278F0bf14B",
      ],
    },
    collection: {
      name: "DeGods",
      imageUrl:
        "https://lh3.googleusercontent.com/aLzN0BfvD8m521UbX_BDyDfIiPQxpLrHbBIpCxrvmGumE5Gz-9M2J6KH3IArUNPIeiv_5ZhHDaZKri-9qFoBm87QLsX5Xg2S9uY",
    },
  },
] as any[];
